import React from 'react'
import { BiSolidQuoteLeft } from 'react-icons/bi';

const TestimonialCard = ({ data }) => {

  const { name, title, summary } = data;

  return (
    <React.Fragment>
      <figure className='space-y-7'>
        <p className='lg:text-lg text-gray-700 sm:text-base lg:leading-loose sm:leading-relaxed'>{summary}</p>
        <div className='flex items-center space-x-4'>
          <div className='bg-ascent p-4'>
            <BiSolidQuoteLeft size={20} />
          </div>
          <div>
            <p className='text-lg font-bold'>{title}</p>
            <p className='text-base text-gray-700'>{name}</p>
          </div>
        </div>
      </figure>
    </React.Fragment>
  )
}

export default TestimonialCard