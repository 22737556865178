import React, { Fragment, use, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { AuthUserLogin } from '../../../redux/actions/AuthAction';
import { useNavigate } from 'react-router-dom';

const LoginModal = ({ isOpen, closeModal }) => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const focusRef = useRef(null);

  const [isRegistred, setIsRegistred] = useState(false);
  const [otpIsSent, setOtpIsSent] = useState(false);

  const [inputFields, setInpuFields] = useState({
    name: '',
    email: '',
    phone: '',
    otp: '',
  });

  const [header, setHeader] = useState();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInpuFields({ ...inputFields, [name]: value });
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!otpIsSent) {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/send-otp`, {
        phone: inputFields.phone
      });
      if (response.data.status) {
        setIsRegistred(response.data.data.is_registred);
        setOtpIsSent(true);
      }
      else {
        toast.error(response.data.message);
      }
    }

    if (otpIsSent && isRegistred) {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`, {
        phone: inputFields.phone,
        otp: inputFields.otp
      });
      if (response.data.status) {
        localStorage.setItem('accessToken', response.data.data.access_token);
        dispatch(AuthUserLogin(response.data.data));
        handleCloseModal();
        navigate('/dashboard/account');
        toast.success(response.data.message);
      }
      else {
        toast.error(response.data.message);
      }
    }

    if (otpIsSent && !isRegistred) {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/register`, {
        name: inputFields.name,
        email: inputFields.email,
        phone: inputFields.phone,
        otp: inputFields.otp
      });
      if (response.data.status) {
        localStorage.setItem('accessToken', response.data.data.access_token);
        dispatch(AuthUserLogin(response.data.data));
        handleCloseModal();
        navigate('/dashboard/account');
        toast.success(response.data.message);
      }
      else {
        toast.error(response.data.message);
      }
    }
  }

  const handleCloseModal = () => {
    setInpuFields({
      name: '',
      email: '',
      phone: '',
      otp: '',
    });
    setIsRegistred(false);
    setOtpIsSent(false);
    closeModal();
  }

  return (
    <React.Fragment>
      <Transition appear as={Fragment} show={isOpen}>
        <Dialog as='div' className={'relative z-50'} onClose={handleCloseModal} initialFocus={focusRef}>

          {/* BackDrop Overlay */}
          <Transition.Child
            as={Fragment}
            enter='ease-in-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in-out duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-black bg-opacity-50' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='min-h-full flex items-center justify-center p-4 text-center'>

              {/* Modal Dialog */}
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0 scale-90'
                enterTo='opacity-100 scale-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-90'>
                <Dialog.Panel className={"relative w-full max-w-sm bg-white max-h-[90vh] overflow-y-auto transform shadow-lg flex flex-col gap-10 lg:px-12 lg:py-14 sm:px-10 sm:py-12 text-left"} ref={focusRef}>
                  <form className='lg:space-y- sm:space-y-4 mb-10' onSubmit={(event) => handleFormSubmit(event)}>
                    <div className='space-y-5'>
                      <div className='text-center space-y-1 py-2'>
                        <h1 className='text-2xl font-semibold text-ascent-dark'>Welcome Back!</h1>
                        <p className='text-xs text-gray-600'>Enter your credentials to continue</p>
                      </div>
                      <div className='space-y-4'>

                        <div className='input-group'>
                          <label htmlFor="phone" className='input-label'>Phone <em>*</em></label>
                          <input type="tel" value={inputFields.phone} name='phone' required={true} className='input-box-md w-full' onChange={(event) => handleInputChange(event)} placeholder='Phone (10 Digits)' minLength={10} maxLength={10} pattern='[0-9]{10}' />
                        </div>

                        {
                          otpIsSent &&
                          <React.Fragment>
                            <div className='input-group'>
                              <label htmlFor="otp" className='input-label'>Verification OTP <em>*</em></label>
                              <input type="tel" value={inputFields.otp} name='otp' required={true} className='input-box-md w-full' onChange={(event) => handleInputChange(event)} placeholder='Enter OTP (4 Digits)' minLength={4} maxLength={4} pattern='[0-9]{4}' />
                            </div>
                            {!isRegistred &&
                              <React.Fragment>
                                <div className='input-group'>
                                  <label htmlFor="name" className='input-label'>Your Name <em>*</em></label>
                                  <input type="text" value={inputFields.name} name='name' required={true} className='input-box-md w-full' onChange={(event) => handleInputChange(event)} placeholder='Your Name' minLength={5} maxLength={100} />
                                </div>

                                <div className='input-group'>
                                  <label htmlFor="email" className='input-label'>Email Address <em>*</em></label>
                                  <input type="email" value={inputFields.email} name='email' required={true} className='input-box-md w-full' onChange={(event) => handleInputChange(event)} placeholder='Email Address' />
                                </div>
                              </React.Fragment>
                            }
                          </React.Fragment>
                        }

                        <div>
                          <button className='btn-primary-md w-full'>{otpIsSent ? "Submit" : "Sent OTP"}</button>
                        </div>
                      </div>

                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
              {/* Modal Dialog */}

            </div>
          </div>

        </Dialog>
      </Transition>
    </React.Fragment>
  )
}

export default LoginModal
