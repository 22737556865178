import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';

const MobileHeader = () => {

  const [sidebarState, setSidebarState] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    setSidebarState(false);
  }, [pathname]);

  return (
    <React.Fragment>
      <header className='fixed top-0 z-50 w-full lg:hidden md:hidden sm:block bg-black shadow-lg'  id='mobile-header'>
        <div className='p-5'>
          <nav className='flex items-center justify-between'>
            <div>
              <Link to={'/'}>
                <img src="/assets/Logo/logo.png" alt="logo" className='h-[40px] w-auto' />
              </Link>
            </div>
            <div>
              <button onClick={() => setSidebarState(!sidebarState)} className='sidebar-toggler-button space-y-2.5 h-10 w-10'>
                <div className={`h-[2px] w-[40px] bg-white rounded-full ${sidebarState && 'rotate-45 !mb-[-5px] mt-[-7px]'}`}></div>
                <div className={`h-[2px] w-[40px] bg-white rounded-full ${sidebarState ? 'hidden' : 'block'}`}></div>
                <div className={`h-[2px] w-[40px] bg-white rounded-full ${sidebarState && '-rotate-45 !mt-[4px]'}`}></div>
              </button>
            </div>
          </nav>
        </div>
      </header>

      <Sidebar state={sidebarState} />
    </React.Fragment>
  )
}

export default MobileHeader