import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaRegCalendarAlt } from "react-icons/fa";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const BookingCard = ({ booking }) => {
  return (
    <React.Fragment>
      <figure className='pt-3'>
        <div className='space-y-2'>
          <h1 className='font-semibold text-base'>{booking.service.name}</h1>
          <p className='flex items-center space-x-1 text-sm'><FaRegCalendarAlt size={12}/><span>Booked on {moment(booking.created_at).format('Do MMMM YYYY')}</span></p>
          <div className='space-y-2'>
            <p className='flex items-center space-x-1 text-sm'>Total Amount  = {process.env.REACT_APP_CURRENCY_SYMBOL}{parseInt(booking.total_amount).toLocaleString('en-IN')}</p>
            <p className='flex items-center space-x-1 text-sm'>Paid  = {process.env.REACT_APP_CURRENCY_SYMBOL}{parseInt(booking.total_paid).toLocaleString('en-IN')}</p>
          </div>
          
          <div className='capitalize'>
          { booking.status === "PAYMENT_PENDING" && <span className='bg-yellow-600 px-2 py-1 text-white rounded text-xs'> {booking.status.replace('_', ' ').toLowerCase()}</span>}
          { booking.status === "CONFIRMED" && <span className='bg-blue-600 px-2 py-1 text-white rounded text-xs'> {booking.status.replace('_', ' ').toLowerCase()}</span>}
          { booking.status === "WORKING" && <span className='bg-blue-600 px-2 py-1 text-white rounded text-xs'> {booking.status.replace('_', ' ').toLowerCase()}</span>}
          { booking.status === "COMPLETED" && <span className='bg-green-600 px-2 py-1 text-white rounded text-xs'> {booking.status.replace('_', ' ').toLowerCase()}</span>}
          { booking.status === "CANCELLED" && <span className='bg-red-600 px-2 py-1 text-white rounded text-xs'> {booking.status.replace('_', ' ').toLowerCase()}</span>}
          { booking.status === "REQUEST_TO_CLOSE" && <span className='bg-orange-500 px-2 py-1 text-white rounded text-xs'>Request to Close</span>}
          </div>
          <div className='py-2'>
            <Link to={`/dashboard/booking/${booking.uuid}`} className='btn-primary-sm'>View Booking Details</Link>
          </div>
        </div>
      </figure>
    </React.Fragment>
  )
}

const Booking = () => {

  const [bookings,setBookings] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/service-quote-booking`,{
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
        }
      });
      if (response.data.status) {
        setBookings(response.data.data);
      }
      else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('An error occured');
    }
  }

  useEffect(() => {
    fetchData();
  },[]);

  return (
    <React.Fragment>
        <figure className='border shadow-lg'>
          <div className='px-5 py-4 space-y-1 border-b'>
            <h1 className='text-base font-medium'>My Bookings</h1>
            <p className='text-xs text-gray-500'>List of all your bookings</p>
          </div>
          <div className='p-5 pt-1 space-y-3 divide-y'>
            {bookings.map(booking => <BookingCard key={booking.id} booking={booking} />)}
          </div>
        </figure>
    </React.Fragment>
  )
}

export default Booking;