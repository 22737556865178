import React from "react";
import Home from "../views/Home/Home";
import Template from "../components/Layouts/Template";
import { Route, Routes } from "react-router-dom";
import Account from "../views/Dashboard/Account/Account";
import Dashboard from "../components/Layouts/Dashboard";
import Quote from "../views/Dashboard/Quote/Quote";
import Setting from "../views/Dashboard/Setting/Setting";
import axios from "axios";
import { useDispatch } from "react-redux";
import { AuthUserLogin } from "../redux/actions/AuthAction";
import Auth from "../middleware/Auth";
import QuotePreview from "../views/Dashboard/QuotePreview/QuotePreview";
import PrivacyPolicy from "../views/PrivacyPolicy/PrivacyPolicy";
import RefundPolicy from "../views/RefundPolicy/RefundPolicy";
import ShippingPolicy from "../views/ShippingPolicy/ShippingPolicy";
import TermsConditions from "../views/TermsConditions/TermsConditions";
import About from "../views/About/About";
import Contact from "../views/Contact/Contact";
import Services from "../views/Services/Services";
import Gallery from "../views/Gallery/Gallery";
import FrequentlyAskedQuestion from "../views/FrequentlyAskedQuestion/FrequentlyAskedQuestion";
import Careers from "../views/Careers/Careers";
import MakeQuote from "../views/MakeQuote/MakeQuote";
import PaymentSuccess from "../views/Dashboard/PaymentSuccess/PaymentSuccess";
import BookingPreview from "../views/Dashboard/BookingPreview/BookingPreview";
import Booking from "../views/Dashboard/Booking/Booking";
import ServiceDetails from "../views/ServiceDetails/ServiceDetails";
import VendorProfile from "../views/VendorProfile/VendorProfile";

const GuestRoutes = () => {
  const dispatch = useDispatch();

  const fetchUser = async () => {
    const token = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status) {
        dispatch(AuthUserLogin(response.data.data));
      } else {
        localStorage.removeItem("accessToken");
      }
    } catch (error) {
      localStorage.removeItem("accessToken");
    }
  };

  if (localStorage.getItem("accessToken")) {
    fetchUser();
  }

  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Template />}>
          <Route index={true} element={<Home />} />

          {/* Protected Routes */}
          <Route path="/" element={<Auth />}>
            <Route element={<Dashboard />}>
              <Route path="/dashboard/account" element={<Account />} />
              <Route path="/dashboard/quote" element={<Quote />} />
              <Route path="/dashboard/quote/:uuid" element={<QuotePreview />} />
              <Route path="/dashboard/booking" element={<Booking />} />
              <Route
                path="/dashboard/booking/:uuid"
                element={<BookingPreview />}
              />
              <Route
                path="/dashboard/booking-payment/:token"
                element={<PaymentSuccess />}
              />
              <Route path="/dashboard/setting" element={<Setting />} />
            </Route>
          </Route>

          {/* Guest Routes  */}
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/services" element={<Services />} />
          <Route path="/service/:service_slug" element={<ServiceDetails />} />
          <Route path="/vendor/:id" element={<VendorProfile />} />
          <Route path="/make-quote/:service_slug?" element={<MakeQuote />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/shipping-policy" element={<ShippingPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route
            path="/frequently-asked-questions"
            element={<FrequentlyAskedQuestion />}
          />
          <Route path="/careers" element={<Careers />} />
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default GuestRoutes;
