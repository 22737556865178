import React from 'react'
import IntroSlider from '../../components/Sections/Home/IntroSlider/IntroSlider';
import About from '../../components/Sections/Home/About/About';
import Features from '../../components/Sections/Home/Features/Features';
import Testimonial from '../../components/Sections/Home/Testimonial/Testimonial';
import Services from '../../components/Sections/Home/Services/Services';
import HowItWorks from '../../components/Sections/Home/HowItWorks/HowItWorks';
import Brand from '../../components/Sections/Home/Brand/Brand';
import Services2 from '../../components/Sections/Home/Services2/Services2';

const Home = () => {
  return (
    <React.Fragment>
      <IntroSlider/>
      <About/>
      <Features/>
      <HowItWorks/>
      <Brand/>
      <Services />
      <Testimonial/>
    </React.Fragment>
  )
}

export default Home;