import { USER_LOGGED_IN, USER_LOGGED_OUT } from "../constants/AuthConstant";

export const AuthUserLogin = (auth) => {
    return {
        type: USER_LOGGED_IN,
        payload: auth
    }
};

export const AuthUserLogout = () => {
    return {
        type: USER_LOGGED_OUT,
        payload: null
    }
};