import React, { Fragment, use, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { toast } from 'react-toastify';

const ConfirmBooking = ({ isOpen, closeModal, quote_id, bid_id }) => {

  const focusRef = useRef(null);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/service-quote-booking`, {
          service_quote_id: quote_id,
          service_quote_bid_id: bid_id
        }, {
          headers: {
            "Content-Type": 'multipart/form-data',
            "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
          },
        });

        if (response.data.status) {
          window.location = response.data.data.payment_link;
        }
        else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error('An error occured');
      }
  }

  const handleCloseModal = () => {
    closeModal();
  }

  return (
    <React.Fragment>
      <Transition appear as={Fragment} show={isOpen}>
        <Dialog as='div' className={'relative z-50'} onClose={handleCloseModal} initialFocus={focusRef}>

          {/* BackDrop Overlay */}
          <Transition.Child
            as={Fragment}
            enter='ease-in-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in-out duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-black bg-opacity-50' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='min-h-full flex items-center justify-center p-4 text-center'>

              {/* Modal Dialog */}
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0 scale-90'
                enterTo='opacity-100 scale-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-90'>
                <Dialog.Panel className={"relative w-full max-w-3xl bg-white max-h-[90vh] overflow-y-auto transform shadow-lg flex flex-col lg:p-10 sm:p-7 text-left"} ref={focusRef}>
                  <form onSubmit={(event) => handleFormSubmit(event)} className='space-y-3'>
                    <div className='space-y-0.5'>
                        <h1 className='text-xl font-semibold'>Confirm this Bid</h1>
                        <p className='text-sm text-gray-500'>You have to agree the below term & conditions to confirm this booking</p>
                    </div>
                    <div className='h-[30vh] overflow-y-scroll border p-2 text-sm'>
                        <h1 className='mb-2 font-medium text-base'>Terms & Conditions</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ipsum dolor sit amet consectetur adipiscing elit duis tristique. Eget nullam non nisi est sit amet. Morbi quis commodo odio aenean. Eget nunc lobortis mattis aliquam faucibus purus in. Morbi tristique senectus et netus et. Libero justo laoreet sit amet cursus sit amet dictum sit. Eu non diam phasellus vestibulum lorem sed risus ultricies tristique. Suspendisse potenti nullam ac tortor vitae. Ornare arcu odio ut sem nulla pharetra diam. Blandit turpis cursus in hac habitasse platea. Nisi est sit amet facilisis magna. Sollicitudin ac orci phasellus egestas tellus rutrum tellus pellentesque. Vel turpis nunc eget lorem dolor sed viverra ipsum. Cras semper auctor neque vitae tempus quam pellentesque. Nisi porta lorem mollis aliquam ut porttitor leo a diam. Vitae elementum curabitur vitae nunc sed velit dignissim sodales ut. <br /><br />

                        Nulla pharetra diam sit amet nisl suscipit. Nulla pharetra diam sit amet. Volutpat commodo sed egestas egestas fringilla phasellus. Ac feugiat sed lectus vestibulum mattis. Ipsum consequat nisl vel pretium. Libero nunc consequat interdum varius. Morbi tristique senectus et netus et malesuada fames ac. Amet risus nullam eget felis eget nunc lobortis mattis aliquam. Eu non diam phasellus vestibulum lorem sed risus ultricies tristique. Pellentesque habitant morbi tristique senectus et netus. Dolor sed viverra ipsum nunc aliquet bibendum enim facilisis gravida. Aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Enim ut tellus elementum sagittis vitae. Non blandit massa enim nec dui nunc. Sed vulputate mi sit amet mauris commodo quis imperdiet. <br /> <br />

                        Nullam eget felis eget nunc lobortis mattis aliquam faucibus purus. Quis vel eros donec ac odio tempor orci dapibus. Ut lectus arcu bibendum at varius vel pharetra vel turpis. Sed vulputate odio ut enim blandit volutpat maecenas volutpat blandit. In pellentesque massa placerat duis ultricies. Euismod quis viverra nibh cras pulvinar mattis nunc sed blandit. Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Congue nisi vitae suscipit tellus mauris a diam maecenas sed. Mattis nunc sed blandit libero volutpat sed. Lobortis scelerisque fermentum dui faucibus in. Sit amet massa vitae tortor. Mattis rhoncus urna neque viverra. Id diam vel quam elementum pulvinar etiam non. Tortor pretium viverra suspendisse potenti nullam. Sit amet porttitor eget dolor morbi.</p>
                    </div>
                    <div className='space-y-3'>
                        <div className='flex items-center justify-start'>
                            <input type="checkbox" required name='accept_terms' className='cursor-pointer' id='accept_terms' />
                            <label htmlFor="accept_terms" className='font-medium text-sm cursor-pointer'>I accept the terms & conditions</label>
                        </div>
                        <button className='btn-primary-sm'>Confirm Booking</button>
                    </div>
                    <div>
                      <p className='text-xs text-gray-500'>Note: You will redirect to payment gateway after confirming the booking</p>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
              {/* Modal Dialog */}

            </div>
          </div>

        </Dialog>
      </Transition>
    </React.Fragment>
  )
}

export default ConfirmBooking