import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MdOutlineArrowForward } from "react-icons/md";
import { FaCircleCheck } from "react-icons/fa6";
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2'

const BookingPreview = () => {

  const { uuid } = useParams();

  const [booking, setBooking] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/service-quote-booking/${uuid}`, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
        }
      });
      if (response.data.status) {
        setBooking(response.data.data);
      }
      else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('An error occured');
    }
  }

  const [inputFields, setInpuFields] = useState({
    amount: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInpuFields({ ...inputFields, [name]: value });
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/make-booking-payment`, {
        booking_id: booking.id,
        amount: inputFields.amount
      }, {
        headers: {
          "Content-Type": 'multipart/form-data',
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
        }
      });
      if (response.data.status) {
        window.location = response.data.data.payment_link;
      }
      else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('An error occured');
    }
  }

  const handleCancelBooking = () => {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'Once you cancel this booking it will restart it.',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: "Close",
      confirmButtonText: "Cancel Booking",
    }).then( async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/service-quote-booking/cancel`, {
            uuid: uuid,
          }, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            },
          });
          if (response.data.status) {
            fetchData();
            toast.success(response.data.message);
          }
          else {
            toast.error(response.data.message);
          }
        } catch (error) {
          toast.error('An error occured');
        }
      }
    });
  }

  const handleCloseFormSubmit = async (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/service-quote-booking/close`, {
        otp: form.get('otp'),
        uuid: booking.uuid
      }, {
        headers: {
          "Content-Type": 'multipart/form-data',
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
        }
      });
      if (response.data.status) {
        fetchData();
        toast.success(response.data.message);
      }
      else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('An error occured');
    }
  }

  useEffect(() => {
    setTimeout(() => {
      fetchData();
    }, 1000);

    if (booking) {
      if (booking.status === "PAYMENT_PENDING") {
        setInpuFields({ ...inputFields, ['amount']: parseInt(booking.service_quote_bid.booking_amount).toFixed(0) });
      }
    }
  }, [uuid]);

  if (!booking) {
    return <Skeleton count={1} height={200} enableAnimation={true} />;
  }

  return (
    <React.Fragment>
      <figure className='border shadow-lg'>
        <div className='px-5 py-4 space-y-1 border-b'>
          <h1 className='text-base font-medium'>Booking Details</h1>
          <p className='text-xs text-gray-500'>Check your bookings details</p>
        </div>
        <div className='p-5'>
          <div className='grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-5'>

            <div className='space-y-3'>
              <h1 className='font-medium'>General Details</h1>
              <div>
                <table className='text-sm font-medium'>
                  <tbody>
                    <tr>
                      <td className='text-gray-500 pr-3 pb-3'>Name</td>
                      <td className='pb-2'>{booking.service_quote.name}</td>
                    </tr>
                    <tr>
                      <td className='text-gray-500 pr-3 pb-3'>Email Address</td>
                      <td className='pb-2'>{booking.service_quote.email}</td>
                    </tr>
                    <tr>
                      <td className='text-gray-500 pr-3 pb-3'>Phome</td>
                      <td className='pb-2'>{booking.service_quote.phone}</td>
                    </tr>
                    <tr>
                      <td className='text-gray-500 pr-3 pb-3'>Location</td>
                      <td className='pb-2'>{booking.service_quote.city} - {booking.service_quote.postal_code}, {booking.service_quote.state}</td>
                    </tr>
                    <tr>
                      <td className='text-gray-500 pr-3 pb-3'>Booked on</td>
                      <td className='pb-2'>{moment(booking.created_at).format('Do MMMM YYYY')}</td>
                    </tr>
                    <tr>
                      <td className='text-gray-500 pr-3 pb-3'>Booking Status</td>
                      <td className='pb-2 capitalize'>
                      { booking.status === "PAYMENT_PENDING" && <span className='bg-yellow-600 px-2 py-1 text-white rounded text-xs'> {booking.status.replace('_', ' ').toLowerCase()}</span>}
                      { booking.status === "WORKING" && <span className='bg-blue-600 px-2 py-1 text-white rounded text-xs'> {booking.status.replace('_', ' ').toLowerCase()}</span>}
                      { booking.status === "CONFIRMED" && <span className='bg-blue-600 px-2 py-1 text-white rounded text-xs'> {booking.status.replace('_', ' ').toLowerCase()}</span>}
                      { booking.status === "COMPLETED" && <span className='bg-green-600 px-2 py-1 text-white rounded text-xs'> {booking.status.replace('_', ' ').toLowerCase()}</span>}
                      { booking.status === "CANCELLED" && <span className='bg-red-600 px-2 py-1 text-white rounded text-xs'> {booking.status.replace('_', ' ').toLowerCase()}</span>}
                      { booking.status === "REQUEST_TO_CLOSE" && <span className='bg-orange-500 px-2 py-1 text-white rounded text-xs'>Request to Close</span>}
                      </td>
                    </tr>
                    <tr>
                      <td className='text-gray-500 pr-3 pb-3'>Total Amount</td>
                      <td className='pb-2'>{process.env.REACT_APP_CURRENCY_SYMBOL}{parseInt(booking.total_amount).toLocaleString('en-IN')} <span className='text-gray-500 font-normal text-xs ml-1'>  {" "}
                        (
                        {booking.service.gst_rate
                          ? `Inclusive ${booking.service.gst_rate}% GST`
                          : "Inclusive 0% GST"}
                        )</span></td>
                    </tr>
                    <tr>
                      <td className='text-gray-500 pr-3 pb-3'>Paid Amount</td>
                      <td className='pb-2'>{process.env.REACT_APP_CURRENCY_SYMBOL}{parseInt(booking.total_paid).toLocaleString('en-IN')}</td>
                    </tr>
                    <tr>
                      <td className='text-gray-500 pr-3 pb-3'>Pending Amount</td>
                      <td className='pb-2'>{process.env.REACT_APP_CURRENCY_SYMBOL}{parseInt(booking.total_amount - booking.total_paid).toLocaleString('en-IN')}</td>
                    </tr>
                    <tr>
                      <td className='text-gray-500 pr-3 pb-3'>Invoice</td>
                      <td className='pb-2'>
                        <a href={`${process.env.REACT_APP_API_BASE_URL}/service-booking-bill-download/${booking.uuid}`}>Download Invoice</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className='space-y-3'>
              <h1 className='font-medium'>Service Details</h1>
              <div>
                <table className='text-sm font-medium'>
                  <tbody>
                    <tr>
                      <td className='text-gray-500 pr-3 pb-3'>Service</td>
                      <td className='pb-2'>{booking.service.name}</td>
                    </tr>
                    <tr>
                      <td className='text-gray-500 pr-3 pb-3'>Summary</td>
                      <td className='pb-2'>{booking.service_quote.summary}</td>
                    </tr>
                    <tr>
                      <td className='text-gray-500 pr-3 pb-3 align-top'>Description</td>
                      <td className='pb-2'>{booking.service_quote.description}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className='space-y-3'>
              <h1 className='font-medium capitalize'>{booking.vendor.type.replace('_', ' ').toLowerCase()} Details</h1>
              <div>
                <table className='text-sm font-medium'>
                  <tbody>
                    <tr>
                      <td className='text-gray-500 pr-3 pb-3'>Name</td>
                      <td className='pb-2 capitalize'>{booking.vendor.name}</td>
                    </tr>
                    <tr>
                      <td className='text-gray-500 pr-3 pb-3'>Phone</td>
                      <td className='pb-2'>{booking.vendor.phone}</td>
                    </tr>
                    <tr>
                      <td className='text-gray-500 pr-3 pb-3'>Email Address</td>
                      <td className='pb-2'>{booking.vendor.email}</td>
                    </tr>
                    <tr>
                      <td className='text-gray-500 pr-3 pb-3'>Verification Status</td>
                      <td className='pb-2 capitalize'>{booking.vendor.is_verified ? 
                      <span className='flex'><FaCircleCheck className='fill-green-500 mt-[2.5px] mr-1' /> Verified {booking.vendor.type.replace('_', ' ').toLowerCase()}</span> : 'Unverified'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
        { booking.status === "PAYMENT_PENDING" && <div className='p-5 border-t'>
          <button onClick={() => handleCancelBooking()} className='py-2 px-6 bg-red-500 text-white text-sm font-medium'>Cancel Booking</button>
        </div> }
      </figure>

      { (booking.status === "CONFIRMED") && <div className='border shadow-lg mt-10'>
        <div className='px-5 py-4 space-y-1 border-b'>
          <h1 className='text-base font-medium'>Work Start OTP</h1>
          <p className='text-xs text-gray-500'>Tell this verification OTP to {booking.vendor.type.replace('_', ' ').toLowerCase()} to start your work</p>
        </div>
        <div className='p-5'>
          <h1 className='text-lg font-semibold'><span>OTP</span> : <span className='tracking-widest'>{booking.start_otp && booking.start_otp.otp}</span></h1>
        </div>
      </div> }

      { (booking.status === "REQUEST_TO_CLOSE") && <div className='border shadow-lg mt-10'>
        <div className='px-5 py-4 space-y-1 border-b'>
          <h1 className='text-base font-medium'>Work Done OTP</h1>
          <p className='text-xs text-gray-500'>Tell this verification OTP to {booking.vendor.type.replace('_', ' ').toLowerCase()} to complete your work</p>
        </div>
        <div className='p-5'>
          <h1 className='text-lg font-semibold'><span>OTP</span> : <span className='tracking-widest'>{booking.close_otp && booking.close_otp.otp}</span></h1>
        </div>
      </div> }

      {/* { (booking.status === "REQUEST_TO_CLOSE") && <form onSubmit={(event) => handleCloseFormSubmit(event)} className='border shadow-lg mt-10'>
        <div className='px-5 py-4 space-y-1 border-b'>
          <h1 className='text-base font-medium'>Close Booking</h1>
          <p className='text-xs text-gray-500'>Enter Verification OTP to close the booking</p>
        </div>
        <div className='p-5'>
          <div className='input-group'>
            <label htmlFor="otp" className='input-label'>Verification OTP <em>*</em></label>
            <div className="flex">
              <input type="tel" name='otp' required={true} className='input-box-md lg:min-w-[200px] lg:w-auto sm:w-full' pattern='[0-9]{4}' placeholder='Enter OTP (4 Digits)' minLength={4} maxLength={4} />
              <button className='btn-primary-sm whitespace-nowrap'>Close Booking</button>
            </div>
          </div>
        </div>
      </form> } */}
    
      { (booking.status === "WORKING" || booking.status === "REQUEST_TO_CLOSE") && <>{ (booking.total_paid < booking.total_amount) && <form onSubmit={(event) => handleFormSubmit(event)} className='border shadow-lg mt-10'>
        <div className='px-5 py-4 space-y-1 border-b'>
          <h1 className='text-base font-medium'>Make Payment</h1>
          <p className='text-xs text-gray-500'>Pay remaining amount for this booking</p>
        </div>
        <div className='p-5'>
          <div className='input-group'>
            <label htmlFor="amount" className='input-label'>Amount <span>(In INR)</span>  
            {((booking.total_amount - booking.total_paid) == booking.total_amount) && <span>  (Minimum: {process.env.REACT_APP_CURRENCY_SYMBOL + parseInt(booking.service_quote_bid.booking_amount)})</span>} <em>*</em></label>
            <div className="flex">
              <input type="number" value={inputFields.amount} name='amount' required={true} className='input-box-md lg:min-w-[200px] lg:w-auto sm:w-full' onChange={(event) => handleInputChange(event)} placeholder='Enter Amount' min={((booking.total_amount - booking.total_paid) == booking.total_amount) ? parseInt(booking.service_quote_bid.booking_amount) : 1} max={booking.total_amount - booking.total_paid} />
              <button className='btn-primary-sm whitespace-nowrap'>Pay Now</button>
            </div>
          </div>
        </div>
      </form> }</> }

      { (booking.status === "PAYMENT_PENDING") && <>{ (booking.total_paid < booking.total_amount) && <form onSubmit={(event) => handleFormSubmit(event)} className='border shadow-lg mt-10'>
        <div className='px-5 py-4 space-y-1 border-b'>
          <h1 className='text-base font-medium'>Make Payment</h1>
          <p className='text-xs text-gray-500'>Pay remaining amount for this booking</p>
        </div>
        <div className='p-5'>
          <div className='input-group'>
            <div className="flex">
              <input type="hidden" value={parseInt(booking.service_quote_bid.booking_amount).toFixed(0)} name='amount' required={true} />
              <button className='btn-primary-sm whitespace-nowrap'>Complete Payment</button>
            </div>
          </div>
        </div>
      </form> }</> }

      {booking.booking_media.length != 0 && 
      <figure className='border shadow-lg mt-10'>
        <div className='px-5 py-4 space-y-1 border-b'>
          <h1 className='text-base font-medium'>Work Images</h1>
          <p className='text-xs text-gray-500'>The work images uploaded by vendor</p>
        </div>
        <div className='p-5 pt-3 divide-y space-y-3'>
          <div className='grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-1 gap-4'>
            {booking.booking_media.map((image) => (
              <div key={image.id} className='h-[200px] w-full overflow-clip border rounded-lg flex items-center justify-center'>
                <img src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${image.path}`} alt="image" className='h-auto w-full' />
              </div>
            ))}
          </div>
        </div>
      </figure> }

      <figure className='border shadow-lg mt-10'>
        <div className='px-5 py-4 space-y-1 border-b'>
          <h1 className='text-base font-medium'>Transaction History</h1>
          <p className='text-xs text-gray-500'>Payment transaction history for this booking</p>
        </div>
        <div className='p-5 pt-3 divide-y space-y-3'>
          {booking.payment_transactions.map((transaction) => (
            <div className='space-y-1 pt-2' key={transaction.id}>
              <p className='font-medium'>{process.env.REACT_APP_CURRENCY_SYMBOL}{parseInt(transaction.amount).toLocaleString('en-IN')}</p>

              <p className='text-sm text-gray-500 capitalize'>Method : {transaction.gateway.replace('_', ' ').toLowerCase()}</p>
              <p className='text-sm text-gray-500'>{moment(transaction.created_at).format('Do MMMM YYYY h:mm A')}</p>
              <p className='text-sm text-gray-500 font-medium capitalize'>
              { transaction.status === "SUCCESS" && <span className='text-green-600'>Payment Successful</span>}
              { transaction.status === "PENDING" && <span className='text-red-600'> Payment Cancelled</span>}
              { transaction.status === "FAILED" && <span className='text-red-600'> {transaction.status.replace('_', ' ').toLowerCase()}</span>}
              </p>
              { transaction.transaction_id ? <p className='text-xs text-gray-500 capitalize'>Transaction ID = {transaction.transaction_id}</p> : null } 
            </div>
          ))}
        </div>
      </figure>
    </React.Fragment>
  )
}

export default BookingPreview;