import React, { useState, useEffect } from "react";
import PageHeader from "../../components/Common/PageHeader/PageHeader"
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BiInfoCircle } from "react-icons/bi";
import axios from 'axios';
import LoginModal from "../../components/Authentication/LoginModal/LoginModal";
import QuoteTerms from "../../components/Modal/QuoteTerms/QuoteTerms";

const MakeQuote = () => {

  const { service_slug } = useParams();

  const [loginModalState, setLoginModalState] = useState(false);
  const [quoteTermsModalState, setQuoteTermsModalState] = useState(false);

  const auth = useSelector((state) => state.AuthReducer);

  const [services, setServices] = useState([]);

  useState(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/service`,{
        headers: {
          "Content-Type": 'multipart/form-data',
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
        },
      });
      setServices(response.data.data);
    } catch (error) {
      toast.error('An error occured');
    }
  }, []);

  const fields = {
    id:(auth.id),
    service_id: '',
    name: '',
    email: '',
    phone: '',
    summary: '',
    description: '',
    flat_building: '',
    landmark: '',
    street: '',
    postal_code: '',
    work_status: '',
    city: '',
    state: '',
    quote_media: []
  };

  const work_status = [
    {
      name: "New Work",
      value: "NEW_WORK",
    },
    {
      name: "Existing Work",
      value: "EXISTING_WORK",
    },
    {
      name: "Half Done",
      value: "HALF_DONE",
    }
  ]

  const [inputFields, setInputFields] = useState(fields);

  useEffect(() => {
    if (auth) {
      setInputFields({
        ...inputFields,
        ['name']: auth.name,
        ['email']: auth.email,
        ['phone']: auth.phone
      });
    }
  }, [auth]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputFields({ ...inputFields, [name]: value });
  }

  const handleQuoteMediaChange = (event) => {
    setInputFields({ ...inputFields, ['quote_media']: event.target.files });
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (!auth) {
      toast.warn('Please verify your account');
      setLoginModalState(true);
      return false;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/service-quote`, inputFields, {
        headers: {
          "Content-Type": 'multipart/form-data',
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
        }
      });
      if (response.data.status) {
        setInputFields(fields);
        event.target.reset();
        toast.success(response.data.message);
      }
      else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('An error occured');
    }
  }

  return (
    <React.Fragment>
      <PageHeader
        title={"Make a Quote"}
        description={"Please fill the form to make a quote"}
        crums={[
          {
            label: "Make a Quote",
            path: "/make-quote",
          },
        ]}
      />
      <section>
        <div className="container lg:py-16 md:py-16 sm:py-7">

          <form onSubmit={(event) => handleFormSubmit(event)}>
            <figure className='border shadow-lg'>
              <div className='px-5 py-4 space-y-1 border-b'>
                <h1 className='text-base font-medium'>Make a Quote</h1>
                <p className='text-xs text-gray-500'>Create a quote for your service</p>
              </div>
              <div className='p-5 space-y-3 divide-y'>

                {auth ? <div className='space-y-4 py-4'>
                  <div>
                    <h1 className='text-sm font-medium'>General Information</h1>
                  </div>
                  <div className='grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5'>

                    {/* Name */}
                    <div className="input-group">
                      <label htmlFor="name" className='input-label'>Your Name <em>*</em></label>
                      <input type="text" name='name' className='input-box-md' placeholder='Enter Name' value={inputFields.name} required minLength={5} maxLength={100} onChange={(event) => handleInputChange(event)} />
                    </div>

                    {/* Email Address */}
                    <div className="input-group">
                      <label htmlFor="email" className='input-label'>Email Address <em>*</em></label>
                      <input type="email" name='email' className='input-box-md' placeholder='Enter Email Address' value={inputFields.email} required minLength={5} maxLength={100} onChange={(event) => handleInputChange(event)} />
                    </div>

                    {/* Phone */}
                    <div className="input-group">
                      <label htmlFor="phone" className='input-label'>Phone <em>*</em></label>
                      <input type="tel" pattern='[0-9]{10}' name='phone' className='input-box-md' placeholder='Enter Phone (10 Digits)' value={inputFields.phone} required minLength={10} maxLength={10} onChange={(event) => handleInputChange(event)} />
                    </div>

                  </div>
                </div> :
                  <div className="pt-4">
                    <div className="bg-ascent bg-opacity-10">
                      <div className="p-5 space-y-3">
                        <div className="space-y-0.5">
                          <h1 className="font-semibold">Verify Your Account</h1>
                          <p className="text-xs text-gray-500">You need to verify your account to continue</p>
                        </div>
                        <div>
                          <button onClick={() => setLoginModalState(true)} type="button" className="btn-primary-sm flex items-center justify-center space-x-1">
                            <span>Verify now</span><BiInfoCircle size={15} /></button>
                        </div>
                      </div>
                    </div>
                  </div>}

                <div className='space-y-4 py-4'>
                  <div>
                    <h1 className='text-sm font-medium'>Service Information</h1>
                  </div>
                  <div className='grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5'>

                    {/* Service ID */}
                    <div className="input-group">
                      <label htmlFor="name" className='input-label'>Service <em>*</em></label>
                      <select defaultValue={inputFields.service_id} name="service_id" className='input-box-md' id="service_id" required onChange={(event) => handleInputChange(event)}>
                        <option value="">Select Service</option>
                        {services.map(({ name, id, slug }) => (
                          <option selected={slug == service_slug} key={id} value={id}>{name}</option>
                        ))}
                      </select>
                    </div>
                          
                    {/* Summary */}
                    <div className="input-group lg:col-span-3">
                      <label htmlFor="summary" className='input-label'>Summary <em>*</em></label>
                      <input type="text" name='summary' className='input-box-md' placeholder='What do you want to do ?' value={inputFields.summary} required minLength={5} maxLength={100} onChange={(event) => handleInputChange(event)} />
                    </div>

                    {/* Description */}
                    <div className="input-group lg:col-span-4">
                      <label htmlFor="description" className='input-label'>Description
                        <em>*</em></label>
                      <textarea name="description" id="description" rows="3" className='input-box-md' placeholder='Describe your problem' defaultValue={inputFields.description} onChange={(event) => handleInputChange(event)} minLength={10} maxLength={1000} required></textarea>
                    </div>

                    {/* Work Status */}
                    <div className="input-group">
                      <label htmlFor="work_status" className='input-label'>Work Status <em>*</em></label>
                      <select defaultValue={inputFields.work_status} name="work_status" className='input-box-md' id="work_status" required onChange={(event) => handleInputChange(event)}>
                        <option value="">Select Work Status</option>
                        {work_status.map((item) => (
                          <option key={item.value} value={item.value}>{item.name}</option>
                        ))}
                      </select>
                    </div>

                    {/* Images */}
                    <div className="input-group">
                      <label htmlFor="quote_media" className='input-label'>Work Images <em>*</em></label>
                      <input type="file" multiple name='quote_media' className='input-box-md' onChange={(event) => handleQuoteMediaChange(event)} />
                    </div>

                  </div>
                </div>

                

                <div className='space-y-4 pt-4'>
                  <div>
                    <h1 className='text-sm font-medium'>Address Information</h1>
                  </div>
                  <div className='grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5'>

                    {/* Flat Building */}
                    <div className="input-group">
                      <label htmlFor="flat_building" className='input-label'>House No. / Building No. / Flat No. <span>(Optional)</span></label>
                      <input type="text" name='flat_building' className='input-box-md' placeholder='Enter House No. / Building No. / Flat No.' value={inputFields.flat_building} minLength={1} maxLength={250} onChange={(event) => handleInputChange(event)} />
                    </div>

                    {/* Landmark */}
                    <div className="input-group">
                      <label htmlFor="landmark" className='input-label'>Landmark <span>(Optional)</span></label>
                      <input type="text" name='landmark' className='input-box-md' placeholder='Enter Landmark' value={inputFields.landmark} minLength={1} maxLength={250} onChange={(event) => handleInputChange(event)} />
                    </div>

                    {/* Street */}
                    <div className="input-group">
                      <label htmlFor="street" className='input-label'>Locality / Street <em>*</em></label>
                      <input type="text" name='street' className='input-box-md' placeholder='Enter Locality / Street' value={inputFields.street} required minLength={1} maxLength={250} onChange={(event) => handleInputChange(event)} />
                    </div>

                    {/* City */}
                    <div className="input-group">
                      <label htmlFor="city" className='input-label'>City <em>*</em></label>
                      <input type="text" name='city' className='input-box-md' placeholder='Enter City' value={inputFields.city} required minLength={2} maxLength={50} onChange={(event) => handleInputChange(event)} />
                    </div>

                    {/* Postal Code */}
                    <div className="input-group">
                      <label htmlFor="postal_code" className='input-label'>Pincode <em>*</em></label>
                      <input type="tel" pattern='[0-9]{6}' name='postal_code' className='input-box-md' placeholder='Enter Pincode (6 Digits)' value={inputFields.postal_code} required minLength={6} maxLength={6} onChange={(event) => handleInputChange(event)} />
                    </div>
                    
                    {/* State */}
                    <div className="input-group">
                      <label htmlFor="state" className='input-label'>State <em>*</em></label>
                      <input type="text" name='state' className='input-box-md' placeholder='Enter State' value={inputFields.state} required minLength={2} maxLength={50} onChange={(event) => handleInputChange(event)} />
                    </div>

                  </div>
                </div>

                <div className='flex items-center justify-start pt-4'>
                  <input type="checkbox" required name='accept_terms' className='cursor-pointer' id='accept_terms' />
                  <label htmlFor="accept_terms" className='font-medium text-sm cursor-pointer'>I accept the terms & conditions <button className="ml-2 text-amber-500" onClick={() => setQuoteTermsModalState(true)}>View Terms & Conditions</button></label>
                </div>

              </div>
              <div className='p-5 space-y-1 border-t'>
                <button type='submit' className='btn-primary-sm md:w-fit sm:w-full'>Submit Quote</button>
              </div>
            </figure>
          </form>

        </div>
      </section>

      <LoginModal isOpen={loginModalState} closeModal={() => setLoginModalState(false)} />
      <QuoteTerms isOpen={quoteTermsModalState} closeModal={() => setQuoteTermsModalState(false)} />
    </React.Fragment>
  );
};

export default MakeQuote;
