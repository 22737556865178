import React from 'react';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';

const FeatureCard = ({ data }) => {
  return (
    <React.Fragment>
      <figure className='lg:px-5 md:px-3 sm:px-0 py-20 feature-card relative flex items-center justify-center group mt-16'>
        <div className='relative z-10'>
          <div className='icon-div p-7 mt-[-140px]'>
            <div className='icon-shape bg-ascent p-0.5 w-fit mx-auto'>
              <div className='h-fit w-fit bg-white group-hover:bg-ascent transition duration-300 ease-in-out group-hover:ease-in-out group-hover:bg-opacity-80 py-7 px-16'>
                <img src={data.icon} alt={data.title} className='h-[60px] w-auto mx-auto' />
              </div>
            </div>
          </div>
          <div className='text-center space-y-5 mt-5'>
            <h3 className='font-bold lg:text-4xl sm:text-3xl leading-loose'>{data.title}</h3>
            <p className='text-gray-500 leading-loose lg:text-base sm:text-sm font-[300]'>{data.summary}</p>
          </div>
          <div className='absolute right-[140px] -bottom-[100px] opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out group-hover:ease-in-out'>
            <button className='p-3 border border-ascent rounded-full bg-white hover:bg-ascent transition duration-300 ease-in-out hover:ease-in-out'>
              <MdKeyboardDoubleArrowRight size={20} />
            </button>
          </div>
        </div>
        <div className='shaped-bg absolute z-0 top-0 h-full w-[300px] bg-complement group-hover:bg-ascent group-hover:bg-opacity-30 transition duration-300 ease-in-out group-hover:ease-in-out' />
      </figure>
    </React.Fragment>
  )
}

export default FeatureCard