import React from 'react';
import { RxExternalLink, RxArrowRight } from 'react-icons/rx';
import { Link } from 'react-router-dom';

const PortfolioCard = ({ data }) => {
  
  const { thumbnail_image, name, summary, slug } = data;

  return (
    <React.Fragment>
      <figure className='relative flex flex-col items-center justify-center overflow-visible'>
        <div className='relative w-full h-0 pb-[56.25%] overflow-hidden'>
          {/* Aspect ratio 16:9 */}
          <img 
            src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${thumbnail_image}`} 
            alt={name} 
            className='absolute top-0 left-0 w-full h-full object-cover' 
          />
        </div>
        <div className='lg:absolute sm:relative lg:w-[80%] bg-white p-7 border z-10 lg:bottom-[-80px] lg:shadow-lg space-y-2'>
          <Link to={`/service/${slug}`}>
            <h3 className='font-bold text-xl'>{name}</h3>
          </Link>
          <p className='text-sm leading-relaxed text-gray-500'>{summary}</p>
          <div className='pt-2 flex items-center justify-start space-x-5'>
            <Link to={`/make-quote/${slug}`} className='btn-primary-sm flex items-center space-x-2 w-fit'>
              <span>Service Request</span>
              <RxExternalLink size={15} strokeWidth={0.5} />
            </Link>
            <Link to={`/service/${slug}`} className='font-medium text-sm flex items-center space-x-1 hover:text-yellow-800 text-yellow-600'>
              <span>Learn more</span> <RxArrowRight strokeWidth={0.5} className='mt-0.5' />
            </Link>
          </div>
        </div>
      </figure>
    </React.Fragment>
  );
}

export default PortfolioCard;