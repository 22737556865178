import React from "react";
import PageHeader from "../../components/Common/PageHeader/PageHeader";
import { GiArcheryTarget } from "react-icons/gi";
import { FaRegEye } from "react-icons/fa6";

const About = () => {
  return (
    <React.Fragment>
      <PageHeader
        title={"About us"}
        description={"Our values and vaulted us to the top of our industry."}
        crums={[
          {
            label: "About us",
            path: "/about",
          },
        ]}
      />
      <section>
        <div className="container py-20">
          <div className="grid sm:grid-cols-1 gap-16 items-center">
            {/* <figure>
              <img src="/assets/About/founder.jpg" alt="about-img" className='lg:h-[300px] md:h-[300px] sm:h-[200px] w-auto mx-auto rounded-lg border shadow-xl' />
            </figure> */}

            <figure className="space-y-7">
              <p className="font-bold">ABOUT HOMEGURUS</p>
              <p className="text-gray-500 font-bold leading-loose">
              Homegurus Constructive Solutions Private Limited is an innovative & technology driven online platform. This Start Up Company plans to operate all over India  rural to metro cities. We are dedicated to serve all construction related services including building consultancy services to all those people who want to build their home, buildings & commercial projects once or more in their life. We have lots of certified & bonafide engineers/ architects, contractors, commercial vehicles owners, professionals & other Service Providers enrolled on our platform to make your dream true. In this Competitive market there are too many different types of product sellers or service providers in which they make it difficult to choose the best product or service and misguide them for. At HomeGurus you got lots of construction tips & suggestions where you found safety and saved your valuable money also.
              </p>
              {/* <h2 className='font-bold text-5xl'>Welcome to HomeGurus</h2> */}
              <p className="text-gray-500 font-bold leading-loose">
                We're on a mission to transform the construction experience in
                India. Our platform connects you with trusted expert
                engineers/architects and contractors of all categories based on
                your Pin code, making construction projects easier, more
                transparent, and cost-effective & It's Very simple to found any
                solutions of your construction needs including awareness. Our
                Online Platform are very helpful for all those peoples who want
                to make their dream home easier as easiest. I Promise that on
                our platform our customers & vendors are going to found their
                growth of knowledge & new way of earning.
              </p>
              <div className="space-y-1">
                <p className="text-lg font-semibold text-gray-900">
                  Dipesh Kumar Singh
                </p>
                <p className="text-sm text-gray-600">CEO-Founder</p>
              </div>
            </figure>
          </div>
        </div>
      </section>

      <section className="border-t">
        <div className="container py-20 space-y-10">
          <div className="flex items-center justify-center text-center flex-col space-y-4">
            <img
              src="/assets/Home/portfolio/portfolio-icon.png"
              alt="portfolio-icon"
              className="h-[50px] w-auto pb-2"
            />
            <h1 className="font-bold lg:text-4xl sm:text-3xl leading-relaxed">
              How HomeGurus Works
            </h1>
          </div>

          <div className="text-center lg:w-8/12 md:w-10/12 sm:w-full mx-auto">
            <p>
              <strong>Contractor Requests:</strong> HomeGurus allows users to request contractors
              based on their pincode, providing access to a wide range of local
              options. Users can compare reviews, ratings, and cost estimates to
              make informed decisions. <br />
              <br />
              <strong>Engineering Consultancy:</strong> Our platform connects individuals and
              businesses with experienced engineers/ architects, ensuring that
              they receive expert guidance tailored to their specific needs and
              location. <br />
              <br />
              <strong>Service Providers Request:</strong> HomeGurus Connects you with all
              categories in Construction Industry certified, Experienced &
              Bonafied working professionals for your every construction related
              needs. There are lots of services for your home or all types of
              building at all stages listed on our platform. Our Support team
              helps you with your needs.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-complement">
        <div className="container py-20">
          <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 items-center lg:w-10/12 md:w-full sm:w-full justify-center mx-auto lg:divide-x sm:divide-x-0 lg:divide-y-0 sm:divide-y">
            <figure className="text-center space-y-10 p-8">
              <div className="p-10 bg-ascent w-fit h-fit mx-auto bg-opacity-10 rounded-full">
                <FaRegEye size={100} className="fill-yellow-600 mx-auto" />
              </div>
              <div className="space-y-5">
                <h1 className="text-4xl font-semibold">Our Vision</h1>
                <hr className="w-[100px] border-2 rounded-full border-ascent mx-auto" />
                <p className="text-gray-600">
                  At HomeGurus Our Vision is to make an easy process for
                  purchasing any products or services for any new home owner who
                  planning for build a new dream home or commercial building
                  become STRESS FREE. We envision a construction industry where
                  every projects & Services is marked by transparency,
                  reliability & excellence through this revolutionary Idea.
                  HomeGurus is committed to facilitating positive construction
                  experiences and contributing to the growth and development of
                  India’s Infrastructure.
                </p>
              </div>
            </figure>

            <figure className="text-center space-y-10 p-8">
              <div className="p-10 bg-ascent w-fit h-fit mx-auto bg-opacity-10 rounded-full">
                <GiArcheryTarget
                  size={100}
                  className="fill-yellow-600 mx-auto"
                />
              </div>
              <div className="space-y-5">
                <h1 className="text-4xl font-semibold">Our Mission</h1>
                <hr className="w-[100px] border-2 rounded-full border-ascent mx-auto" />
                <p className="text-gray-600">
                  At HomeGurus Our mission is to provide one-stop solutions for
                  all construction related needs, easiest & cost-effective
                  start-to-end full solutions for their new dream home, existing
                  home, commercial construction, and turnkey interior design. We
                  aim to empower customers with choices, transparency and
                  expertise making the process of finding reliable contractors &
                  seeking engineering consultancy seamless and efficient. We aim
                  to take on each project with a personalized approach, ensuring
                  our client’s visions come to life with unmatched quality, on
                  time, and within budget.
                </p>
              </div>
            </figure>
          </div>
        </div>
      </section>
      <section className="border-t">
        <div className="container py-20 space-y-10">
          <div className="flex items-center justify-center text-center flex-col space-y-4">
            <img
              src="/assets/Home/portfolio/portfolio-icon.png"
              alt="portfolio-icon"
              className="h-[50px] w-auto pb-2"
            />
            <h1 className="font-bold lg:text-4xl sm:text-3xl leading-relaxed">
              The construction industry in India
            </h1>
          </div>

          <div className="text-left lg:w-6/12 md:w-10/12 lg:pl-0 sm:pl-3 sm:w-full mx-auto">
            <ul className="list-decimal space-y-3 ">
              <li>
                <p className="text-lg font-semibold">Lack of Transparency</p>
                <ul className="list-disc pl-3">
                  <li>
                    Transparency issues plague the construction industry in
                    India, making it difficult for customers to make informed
                    decisions.
                  </li>
                  <li>
                    Unclear pricing, hidden costs, and inconsistent quality
                    standards often lead to disputes and dissatisfaction.
                  </li>
                </ul>
              </li>

              <li>
                <p className="text-lg font-semibold">
                  Finding Reliable Contractors
                </p>
                <ul className="list-disc pl-3">
                  <li>
                    Locating trustworthy and skilled contractors can be a
                    daunting task.
                  </li>
                  <li>
                    Customers often struggle to identify professionals with a
                    proven track record, causing delays and budget overruns.
                  </li>
                </ul>
              </li>

              <li>
                <p className="text-lg font-semibold">Expertise Gap</p>

                <ul className="list-disc pl-3">
                  <li>
                    Access to expert engineering consultancy is limited,
                    particularly in rural areas.
                  </li>
                  <li>
                    Lack of guidance can result in subpar construction
                    practices, compromising safety and quality.
                  </li>
                </ul>
              </li>

              <li>
                <p className="text-lg font-semibold">Cost Overruns</p>
                <ul className="list-disc pl-3">
                  <li>
                    Construction projects frequently exceed budget expectations,
                    straining financial resources.
                  </li>
                  <li>
                    Inefficient cost management practices and unexpected
                    expenses contribute to these overruns.
                  </li>
                </ul>
              </li>

              <li>
              <p className="text-lg font-semibold">Inadequate Information</p>
                <ul className="list-disc pl-3">
                  <li>
                    Access to comprehensive project information, including local
                    resources and materials, is limited.
                  </li>
                  <li>
                    This scarcity of information makes it challenging to
                    optimize construction projects.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="bg-complement">
        <div className="container py-20 space-y-10">
          <div className="flex items-center justify-center text-center flex-col space-y-4">
            <img
              src="/assets/Home/portfolio/portfolio-icon.png"
              alt="portfolio-icon"
              className="h-[50px] w-auto pb-2"
            />
            <h1 className="font-bold lg:text-4xl sm:text-3xl leading-relaxed">
              HomeGurus: Your One-Stop Solution
            </h1>
          </div>

          <div className="text-center lg:w-8/12 md:w-10/12 sm:w-full mx-auto">
            <p>
              HomeGurus has recognized the pressing need for a transformative
              solution in India's construction industry. We have developed a
              user-friendly platform that connects customers with trusted
              contractors and experienced engineers based on their pincode. Our
              commitment to transparency, cost-effectiveness, and accessibility
              aims to bridge the gaps in the construction industry.
            </p>
            <p>
              By using HomeGurus, customers gain access to a wealth of
              information, enabling them to make informed choices, find reliable
              contractors, and seek expert guidance. We are dedicated to
              simplifying the construction experience, saving you time, money,
              and stress.
            </p>
            <p>
              Together, we can overcome the challenges that have long hindered
              the construction industry in India, and embark on a journey
              towards a more efficient, transparent, and successful construction
              sector.
            </p>
          </div>

          <div className="flex items-center justify-center text-center">
            <h1 className="font-bold lg:text-4xl sm:text-3xl leading-relaxed">
              Our Solution: Innovative Platform At HomeGurus, We Have Developed
              An Innovative & Revolutionary Idea
            </h1>
          </div>

          <div className="text-center lg:w-8/12 md:w-10/12 sm:w-full mx-auto space-y-2">
            <p>
              At HomeGurus, we have developed an innovative platform designed to
              simplify the construction experience for our valued users. In
              Which they can search & demand work quotation from any service
              providers easily, any Services as per their needs Our platform
              offers two primary services:
            </p>

            <div>
              <p className="text-lg font-semibold">
                Full Construction Service :{" "}
              </p>
              <p>
                Construction services refer to various tasks and obligations
                associated with the construction process. These include all
                labor, every category, services, and building materials provided
                in tandem with the construction, repair, reconstruction,
                modification, demolition, or other improvements to a property or
                a city facility.
              </p>
            </div>

            <div>
              <p className="text-lg font-semibold">
                Product & Technical Consultancy :{" "}
              </p>
              <p>
                Seeking expert guidance for your construction project has never
                been easier. Our Platform, trying to solve these situations,
                handles all these problems in which HomeGurus connects users
                with experienced engineers/ architects & expert professionals of
                all categories who offer tailored consultancy services,
                addressing specific project needs.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="border-t">
        <div className="container py-20 space-y-10">
          <div className="flex items-center justify-center text-center flex-col space-y-4">
            <img
              src="/assets/Home/portfolio/portfolio-icon.png"
              alt="portfolio-icon"
              className="h-[50px] w-auto pb-2"
            />
            <h1 className="font-bold lg:text-4xl sm:text-3xl leading-relaxed">
              Types of Construction
            </h1>
          </div>

          <div className="text-left lg:w-6/12 md:w-10/12 lg:pl-0 sm:pl-5 sm:w-full mx-auto">
            <ul className="list-decimal space-y-3">
              <li>
                <h6 className="text-lg font-semibold">
                  Pre-Construction Services
                </h6>
                <p className="text-sm">
                  Pre-construction services are the starting point for
                  everything. This is the phase when an idea becomes a project.
                  At this stage, the practical aspects of the vision under
                  consideration undergo scrutiny.
                </p>
              </li>

              <li>
                <h6 className="text-lg font-semibold">
                  Construction Project Management
                </h6>
                <p className="text-sm">
                  When a construction project is underway, there are many tasks
                  and responsibilities. Construction project management is
                  divided into three main stages: planning, execution, and
                  post-construction.
                </p>
              </li>

              <li>
                <h6 className="text-lg font-semibold">
                  Building Construction Services
                </h6>
                <p className="text-sm">
                  This section focuses on practical tasks and construction
                  services related to a building's life cycle. It includes the
                  following construction services to ensure that buildings are
                  safe, functional, and comfortable
                </p>
              </li>

              <li>
                <h6 className="text-lg font-semibold">
                  {" "}
                  Remedial Or Repairing Services
                </h6>
                <p className="text-sm">
                  Establishments require regular maintenance. Maintenance
                  workers conduct regular inspections to identify problem areas.
                  They then perform repairs to maintain a safe environment in
                  the building and keep it functioning properly
                </p>
              </li>

              <li>
                <h6 className="text-lg font-semibold"> Maintenance Services</h6>
                <p className="text-sm">
                  Along with repairing and building, construction companies
                  offer many maintenance services.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default About;
