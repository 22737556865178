import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import reportWebVitals from './tests/reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { legacy_createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import AuthReducer from './redux/reducers/AuthReducer';

const store = legacy_createStore(combineReducers({AuthReducer}));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
      <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();
