import React from 'react';
import { RxExternalLink, RxArrowRight } from 'react-icons/rx';
import { Link } from 'react-router-dom';

const ServiceCard = ({ data }) => {
  
  const { thumbnail_image, name, summary, slug } = data;

  return (
    <React.Fragment>
      <figure className='relative flex flex-col items-center justify-center overflow-clip border shadow-lg'>
        <div className='border lg:h-[250px] overflow-clip'>
          <img src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${thumbnail_image}`} alt={name} className='h-auto w-full' />
        </div>
        <div className='bg-white p-7 z-10 space-y-2'>
          <Link to={`/service/${slug}`}>
            <h3 className='font-bold text-xl'>{name}</h3>
          </Link>
          <p className='text-sm leading-relaxed text-gray-500'>{summary}</p>
          <div className='pt-2 flex items-center justify-start space-x-5'>
            <Link to={`/make-quote/${slug}`} className='btn-primary-sm flex items-center space-x-2 w-fit'>
              <span>Service Request</span>
              <RxExternalLink size={15} strokeWidth={0.5} />
            </Link>
            <Link to={`/service/${slug}`} className='font-medium text-sm flex items-center space-x-1 hover:text-yellow-800 text-yellow-600'>
              <span>Learn more</span> <RxArrowRight strokeWidth={0.5} className='mt-0.5' />
            </Link>
          </div>
        </div>
      </figure>
    </React.Fragment>
  )
}

export default ServiceCard