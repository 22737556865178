import React from "react";
import { Link } from "react-router-dom";
import { TbChevronRight } from "react-icons/tb";
import { IoHome } from "react-icons/io5";

const PageHeader = ({ title, description, crums, background }) => {
  return (
    <React.Fragment>
      <section className="bg-cover bg-center bg-no-repeat bg-black" style={{ backgroundImage: background ? `linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url('${background}')` : "url('/assets/background-cover.png')" }}>
        <div className="container space-y-5 lg:py-20 md:py-16 sm:py-10">

          <div className="space-y-3">
            <h1 className="text-white lg:text-5xl md:text-4xl sm:text-3xl font-semibold">{title}</h1>
            <p className="text-sm text-gray-300">{description}</p>
          </div>

        </div>
        <div className="container py-5 bg-black bg-opacity-70">
            <ul className="flex items-center space-x-3 text-gray-200 text-[0.8rem]">
              <li>
                <Link to={"/"} className="flex items-center font-medium">
                  <span className="mr-3 text-gray-200"><IoHome size={15} /></span>
                  <span>Home</span>
                </Link>
              </li>
              {crums.map(({ label, path }) => (
                <li key={label}>
                  <Link to={path} className="flex items-center font-medium">
                    <span className="mr-3 text-gray-200"><TbChevronRight size={15} /></span> {label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
      </section>
    </React.Fragment>);
}

export default PageHeader;