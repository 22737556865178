import React from 'react';
import PageHeader from '../../components/Common/PageHeader/PageHeader';

const ShippingPolicy = () => {
  return (
    <React.Fragment>
      <PageHeader title={"Shipping & Delivery Policy"} description={"Our Shipping & Delivery Policy"} crums={[ {
        label: "Shipping & Delivery Policy",
        path: "/shipping-policy"
      }]} />
      <section>
        <div className='container py-10 space-y-10'>

          <div>
            <h1 className='text-3xl font-bold'>Shipping & Delivery Policy</h1>
          </div>


          <article className='policies'>
            <p>For International buyers, orders are shipped and delivered through registered international courier companies and/or International speed post only. For domestic buyers, orders are shipped through registered domestic courier companies and /or speed post only. Orders are shipped within 0-2 Days days or as per the delivery date agreed at the time of order confirmation and delivering of the shipment subject to Courier Company / post office norms.</p>
            <p>HOMEGURUS CONSTRUCTIVE SOLUTIONS PRIVATE LIMITED is not liable for any delay in delivery by the courier company / postal authorities and only guarantees to hand over the consignment to the courier company or postal authorities within 0-2 Days days from the date of the order and payment or as per the delivery date agreed at the time of order confirmation. Delivery of all orders will be to the address provided by the buyer. Delivery of our services will be confirmed on your mail ID as specified during registration. For any issues in utilizing our services you may contact our helpdesk on 9835431226 or homegurusofficial@gmail.com.</p>
          </article>          

        </div>
      </section>
    </React.Fragment>
  )
}

export default ShippingPolicy;