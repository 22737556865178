import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import PageHeader from '../../components/Common/PageHeader/PageHeader';
import { FaCircleCheck, FaMapPin } from "react-icons/fa6";
import { HiOutlineLocationMarker } from "react-icons/hi";

const VendorProfile = () => {

  const [vendor, setVendor] = useState(null);
  const [services, setServices] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    try {
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/vendor/${id}`)
        .then((response) => {
          setVendor(response.data.data.vendor);
          setServices(response.data.data.services);
        });
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  if (!vendor) {
    return "Loading";
  }

  return (
    <React.Fragment>
      <PageHeader title={"Vendor Details"} description={"Check vendor details"} crums={[{
        label: "Vendor Details",
        path: `/vendor/${id}`
      }]} background={'/assets/background-cover.png'} />
      <section>

        <div className='container py-10 lg:space-y-7 sm:space-y-5'>

          <div className='flex lg:flex-row md:flex-row sm:flex-col lg:items-center md:items-center sm:items-start gap-5'>
            <div>
              <div className='h-[110px] w-[110px] rounded-lg flex items-center justify-center overflow-clip border shadow-lg'>
                <img src={vendor.profile ? `${process.env.REACT_APP_STORAGE_BASE_URL}/${vendor.profile}` : '/assets/default-profile.png'} alt="vendor-profile" className='h-auto w-[130px]' />
              </div>
            </div>
            <div className='space-y-1'>
              <h1 className='font-semibold text-2xl capitalize'>{vendor.name}</h1>
              <div className='text-sm text-gray-500 flex items-start space-x-1'>
                <span>Verified {vendor.type.charAt(0).toUpperCase() + vendor.type.toLowerCase().slice(1)}</span> <FaCircleCheck className='fill-green-500 mt-[3.5px]' />
              </div>
              <p className='text-base text-gray-500 flex items-center gap-1'>
                <HiOutlineLocationMarker className='stroke-gray-500' size={20} />
                <span>{vendor.address.city}, {vendor.address.state}</span>
              </p>
            </div>
          </div>

          <hr />

          <div className='space-y-5'>
            <div className='space-y-1'>
              <h1 className='text-xl font-semibold'>Services</h1>
              <p className='text-xs text-gray-500'>Services offerd by <span className='capitalize'>{vendor.name}</span></p>
            </div>
            <div className='flex flex-wrap gap-2'>
              {services.map((service) => {
                return (
                  <React.Fragment>
                    <Link to={`/service/${service.slug}`}>
                      <button className='px-3 py-1 bg-gray-200 text-xs rounded'>{service.name}</button>
                    </Link>
                  </React.Fragment>
                )
              })}
            </div>
          </div>

          <hr />

          <div className='space-y-5'>
            <div className='space-y-1'>
              <h1 className='text-xl font-semibold'>Portfolio</h1>
              <p className='text-xs text-gray-500'>Previous work done by <span className='capitalize'>{vendor.name}</span></p>
            </div>

            <div className='grid lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-2 gap-3 pb-2'>
              {vendor.portfolio.map((image) => <div key={image.id} className='h-[120px] w-full overflow-clip border rounded-lg flex items-center justify-center'>
                <a target='_blank' href={`${process.env.REACT_APP_STORAGE_BASE_URL}/${image.path}`}>
                  <img src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${image.path}`} alt="image" className='h-auto w-full' /></a>
              </div>)}
            </div>
          </div>

        </div>

      </section>
    </React.Fragment>
  )
}

export default VendorProfile
