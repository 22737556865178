import React from 'react';
import FeatureCard from './FeatureCard';

const featureData = [
  {
    title: "Contractor",
    summary: "At HomeGurus, we specialized in providing top-notch all categories of contractor services to meet all your needs. Whether you're planning a renovation project, constructing a new building, or in need of repairs, our team helps you to connect skilled contractors here to deliver exceptional results.",
    icon: "/assets/Home/features-icons/icon-1.svg"
  },
  {
    title: "Architect Engineer",
    summary: "At HomeGurus Architect / Engineers Consultancy Services, we are committed to delivering exceptional architectural and engineering solutions tailored to meet your unique needs.",
    icon: "/assets/Home/features-icons/icon-2.svg"
  },
  {
    title: "Contruction Awareness",
    summary: "At HomeGurus we strictly committed to arrange or provide Construction Awareness programs to customers & all contractors, we are dedicated to promoting knowledge and understanding within the construction industry.",
    icon: "/assets/Home/features-icons/icon-3.svg"
  }
]

const Features = () => {
  return (
    <React.Fragment>
      <section>
        <div className='container lg:py-20 sm:py-16'>
          <div className='grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-10'>
            {featureData.map((item, index) => <FeatureCard key={index} data={item} />)}
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Features