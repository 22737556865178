import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BsChevronRight, BsArrowRightShort } from 'react-icons/bs';
import { BiMessageSquareDetail } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import LoginModal from '../../Authentication/LoginModal/LoginModal';
import axios from 'axios';

const SidebarTab = ({ text, link, handleClick }) => {

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className='border-b pb-3'>
        <button onClick={link ? () => navigate(link) : handleClick} className='py-1 px-2 text-black capitalize flex items-center justify-between w-full'>
          <span className="text-lg mt-1">{text}</span>
          <BsChevronRight size={20} />
        </button>
      </div>
    </React.Fragment>
  )
}

const Sidebar = ({ state }) => {

  const auth = useSelector((state) => state.AuthReducer);

  const [loginModalState, setLoginModalState] = useState(false);

  const [services, setServices] = useState([]);

  const [serviceDropdownState, setServiceDropdownState] = useState(false);

  useEffect(() => {
    try {
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/service`)
      .then((response) => {
        setServices(response.data.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <React.Fragment>
      <aside className={`sidebar ${state ? 'active' : 'inactive'}`}>
        <div className='mt-28 flex flex-col space-y-3 px-5 max-h-[84vh] overflow-auto'>
          <SidebarTab text={'Home'} link={'/'} />
          <SidebarTab text={'About us'} link={'/about'} />
          <SidebarTab text={'Contact us'} link={'/contact'} />
          <SidebarTab text={'Gallery'} link={'/gallery'} />
          <div className='border-b pb-3'>
            <button onClick={() => setServiceDropdownState(!serviceDropdownState)} className='py-1 px-2 text-black capitalize flex items-center justify-between w-full'>
              <span className="text-lg mt-1">Services</span>
              <BsChevronRight size={20} className={serviceDropdownState ? 'rotate-90' : 'rotate-0'} />
            </button>
            <div className={`p-2 ${serviceDropdownState ? 'block' : 'hidden'}`}>
              <div className={`flex flex-col space-y-3 pt-2`}>
                {services.map((item, index) => (<Link key={index} to={`/service/${item.slug}`} className='text-base'>{item.name}</Link>))}
              </div>
            </div>
          </div>
          {auth ?
            <React.Fragment>
              <SidebarTab text={'Dashboard'} link={'/dashboard/account'} />
              <SidebarTab text={'My Quotes'} link={'/dashboard/quote'} />
              <SidebarTab text={'My Bookings'} link={'/dashboard/booking'} />
              <SidebarTab text={'Account Settings'} link={'/dashboard/setting'} />
            </React.Fragment>
            :
            <React.Fragment>
              <SidebarTab text={'Privacy Policy'} link={'/privacy-policy'} />
              <SidebarTab text={'Terms & Conditions'} link={'/terms-conditions'} />
            </React.Fragment>
          }
          <div className='pt-2'>
            {auth ?
              <Link to={'/make-quote'} className='btn-primary-md w-full flex items-center justify-center space-x-1'>
                <span>Service Request</span>
                <BiMessageSquareDetail size={25} strokeWidth={0.3} />
              </Link>
              :
              <button onClick={() => setLoginModalState(true)} className='btn-primary-md w-full flex items-center justify-center space-x-1'>
                <span>Login to Continue</span>
                <BsArrowRightShort size={25} strokeWidth={0.3} />
              </button>
            }
          </div>
        </div>
      </aside>

      <LoginModal isOpen={loginModalState} closeModal={() => setLoginModalState(false)} />

    </React.Fragment>
  )
}

export default Sidebar;