import axios from 'axios';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FaRegCalendarAlt } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const QuoteCard = ({ quote }) => {
  return (
    <React.Fragment>
      <figure className='py-3'>
        <div className='space-y-2'>
          <h1 className='font-semibold text-base'>{quote.service.name}</h1>
          <p className='flex items-center space-x-1 text-sm'><FaRegCalendarAlt size={12}/><span>{moment(quote.created_at).format('Do MMMM YYYY')}</span></p>
          <p className='flex items-center space-x-1 text-sm'><FiMapPin size={12}/><span>{quote.city} - {quote.postal_code}, {quote.state}</span></p>
          <p className='flex items-center space-x-1 text-xs capitalize rounded-sm overflow-clip w-fit'>
          { quote.status === "QUOTED" && <span className='bg-green-600 px-2 py-1 text-white rounded text-xs'> {quote.status.replace('_', ' ').toLowerCase()}</span>}
          { quote.status === "BID_CLOSED" && <span className='bg-red-600 px-2 py-1 text-white rounded text-xs'> {quote.status.replace('_', ' ').toLowerCase()}</span>}
          </p>
          <div className='pt-2'>
          <Link to={`/dashboard/quote/${quote.uuid}`} className='btn-primary-sm'>View Quote Details</Link>
          </div>
        </div>
      </figure>
    </React.Fragment>
  )
}

const Quote = () => {

  const [quotes,setQuotes] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/service-quote`,{
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
        }
      });
      if (response.data.status) {
        setQuotes(response.data.data);
      }
      else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('An error occured');
    }
  }

  useEffect(() => {
    fetchData();
  },[]);

  return (
    <React.Fragment>
        <figure className='border shadow-lg'>
          <div className='px-5 py-4 space-y-1 border-b'>
            <h1 className='text-base font-medium'>My Quotes</h1>
            <p className='text-xs text-gray-500'>Check your previous quotes</p>
          </div>
          <div className='p-5 pt-1 space-y-3 divide-y'>
            {quotes.map(quote => <QuoteCard key={quote.id} quote={quote} />)}
          </div>
        </figure>
    </React.Fragment>
  )
}

export default Quote;