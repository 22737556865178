import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MdOutlineArrowForward } from "react-icons/md";
import { FaCircleCheck } from "react-icons/fa6";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import ConfirmBooking from "../../../components/Modal/ConfirmBooking/ConfirmBooking";

const QuotePreview = () => {
  const { uuid } = useParams();

  const [quote, setQuote] = useState(null);
  const [quoteBids, setQuoteBids] = useState([]);
  const [bookingConfirmModal, setBookingConfirmModal] = useState(false);
  const [selectedBidId, setSelectedBidId] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/service-quote/${uuid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      console.log(response);
      if (response.data.status) {
        setQuote(response.data.data.service_quote);
        setQuoteBids(response.data.data.service_quote_bids);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occured");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      fetchData();
    }, 1000);
  }, [uuid]);

  const handleBookQuote = (bid_id) => {
    setSelectedBidId(bid_id);
    setBookingConfirmModal(true);
  };

  if (!quote) {
    return <Skeleton count={1} height={200} enableAnimation={true} />;
  }

  return (
    <React.Fragment>
      <figure className="border shadow-lg">
        <div className="px-5 py-4 space-y-1 border-b">
          <h1 className="text-base font-medium">Quote Details</h1>
          <p className="text-xs text-gray-500">Check your quote details</p>
        </div>
        <div className="p-5">
          <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-5">
            <div className="space-y-3">
              <h1 className="font-medium">General Details</h1>
              <div>
                <table className="text-sm font-medium">
                  <tbody>
                    <tr>
                      <td className="text-gray-500 pr-3 pb-3">Name</td>
                      <td className="pb-2">{quote.name}</td>
                    </tr>
                    <tr>
                      <td className="text-gray-500 pr-3 pb-3">Email Address</td>
                      <td className="pb-2">{quote.email}</td>
                    </tr>
                    <tr>
                      <td className="text-gray-500 pr-3 pb-3">Phone</td>
                      <td className="pb-2">{quote.phone}</td>
                    </tr>
                    <tr>
                      <td className="text-gray-500 pr-3 pb-3">Location</td>
                      <td className="pb-2">
                        {quote.city} - {quote.postal_code}, {quote.state}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-gray-500 pr-3 pb-3">Quoted on</td>
                      <td className="pb-2">
                        {moment(quote.created_at).format("Do MMMM YYYY")}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-gray-500 pr-3 pb-3">Quoted Status</td>
                      <td className="pb-2 capitalize">
                        <div>
                          {quote.status === "QUOTED" && (
                            <span className="bg-green-600 px-2 py-1 text-white rounded text-xs">
                              {" "}
                              {quote.status.replace("_", " ").toLowerCase()}
                            </span>
                          )}
                          {quote.status === "BID_CLOSED" && (
                            <span className="bg-red-600 px-2 py-1 text-white rounded text-xs">
                              {" "}
                              {quote.status.replace("_", " ").toLowerCase()}
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="space-y-3">
              <h1 className="font-medium">Service Details</h1>
              <div>
                <table className="text-sm font-medium">
                  <tbody>
                    <tr>
                      <td className="text-gray-500 pr-3 pb-3">Service</td>
                      <td className="pb-2">{quote.service.name}</td>
                    </tr>
                    <tr>
                      <td className="text-gray-500 pr-3 pb-3">Summary</td>
                      <td className="pb-2">{quote.summary}</td>
                    </tr>
                    <tr>
                      <td className="text-gray-500 pr-3 pb-3 align-top">
                        Description
                      </td>
                      <td className="pb-2">{quote.description}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </figure>

      <figure className="border shadow-lg mt-10">
        <div className="px-5 py-4 space-y-1 border-b">
          <h1 className="text-base font-medium">Quote Bids</h1>
          <p className="text-xs text-gray-500">List of bids for your quote</p>
        </div>
        <div className="p-5">
          <div className="space-y-4">
            {quoteBids.map((bid) => (
              <div key={bid.id}>
                <div className="space-y-2 border-b pb-4">
                  <div className="flex flex-col space-y-1">
                    <span className="text-base font-semibold text-gray-900">
                      Vendor ID: #{bid.vendor_id}
                    </span>
                    <span className="text-base font-semibold text-gray-900">
                      {bid.vendor.name}
                    </span>
                    <div className="text-xs text-gray-500 flex items-start space-x-1">
                      <span>
                        Verified{" "}
                        {bid.vendor.type.charAt(0).toUpperCase() +
                          bid.vendor.type.toLowerCase().slice(1)}
                      </span>{" "}
                      <FaCircleCheck className="fill-green-500 mt-[2.5px]" />
                    </div>
                  </div>
                  <div>
                    <Link to={`/vendor/${bid.vendor.id}`}>
                      <button className="px-3 py-1 bg-gray-200 text-xs rounded">
                        View Vendor Details
                      </button>
                    </Link>
                  </div>
                  <div className="text-base space-x-1 font-medium">
                    <span className="text-gray-500">Total Amount</span> =
                    <span className="text-gray-900">
                      {process.env.REACT_APP_CURRENCY_SYMBOL}
                      {parseInt(bid.total_amount).toLocaleString("en-IN")}{" "}
                      <span className="text-xs text-gray-500">
                        {" "}
                        (
                        {quote.service.gst_rate
                          ? `Inclusive ${quote.service.gst_rate}% GST`
                          : "Inclusive 0% GST"}
                        )
                      </span>
                    </span>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">
                      Remark : {bid.remark}
                    </p>
                  </div>
                  {bid.vendor.booking_media.length > 0 && (
                    <div className="space-y-2">
                      <p className="text-sm font-medium">Previous Work</p>
                      <div className="grid lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-3 gap-2 pb-2">
                        {bid.vendor.booking_media.map((image) => (
                          <div
                            key={image.id}
                            className="h-[100px] w-full overflow-clip border rounded-lg flex items-center justify-center"
                          >
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={`${process.env.REACT_APP_STORAGE_BASE_URL}/${image.path}`}
                            >
                              <img
                                src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${image.path}`}
                                alt="user profile"
                                className="h-auto w-full"
                              />
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {quote.status == "QUOTED" ? (
                    <div>
                      <button
                        onClick={() => handleBookQuote(bid.id)}
                        className="btn-primary-sm flex items-start space-x-1"
                      >
                        <span>
                          Book at {process.env.REACT_APP_CURRENCY_SYMBOL}
                          {parseInt(bid.booking_amount).toLocaleString("en-IN")}
                        </span>
                        <MdOutlineArrowForward size={16} />
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            ))}
            {quoteBids.length == 0 && (
              <div>
                <div className="text-left flex flex-col space-y-1">
                  <h1 className="font-medium text-yellow-600">
                    Bids not Found!
                  </h1>
                  <p className="text-gray-500 text-sm">
                    We didn't got any bid for your quote request
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </figure>

      <ConfirmBooking
        isOpen={bookingConfirmModal}
        closeModal={() => setBookingConfirmModal(false)}
        quote_id={quote.id}
        bid_id={selectedBidId}
      />
    </React.Fragment>
  );
};

export default QuotePreview;
