import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import DashboardSidebar from '../Common/DashboardSidebar/DashboardSidebar'
import Breadcrumb from '../Common/Breadcrumb/Breadcrumb';

const Dashboard = () => {

  const { pathname } = useLocation();

  const tabData = [
    {
      label: "Dashboard",
      path: "/dashboard/account"
    },
    {
      label: "My Quotes",
      path: "/dashboard/quote"
    },
    {
      label: "My Bookings",
      path: "/dashboard/booking"
    },
    {
      label: "Setting",
      path: "/dashboard/setting"
    }
  ];

  return (
    <React.Fragment>
      {tabData.map((item, index) => (pathname.includes(item.path)) && <Breadcrumb key={index} crums={[item]} /> )}
      <section>
        <div className='container lg:py-10 md:py-8 sm:py-6 space-y-7'>
          <div>
            <h1 className='font-semibold text-3xl'>
              {tabData.map(({ path, label }) => (pathname.includes(path)) && label )}
            </h1>
          </div>
          <div className='grid lg:grid-cols-10 md:grid-cols-6 sm:grid-cols-1 gap-10'>
            <div className='lg:col-span-2 md:col-span-2 sm:col-span-1 lg:block md:block sm:hidden'>
              <DashboardSidebar />
            </div>
            <div className='lg:col-span-8 md:col-span-4 sm:col-span-1'>
              <Outlet />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Dashboard