import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';

const PaymentSuccess = () => {

  const { token } = useParams();

  const [paymentData, setPaymentData] = useState(null);
  
  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/booking-payment-details/${token}`, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
        }
      });
      setPaymentData(response.data.data);
    } catch (error) {
      toast.error('An error occured');
    }
  }

  useEffect(() => {
    fetchData();
  }, [token]);

  if (!token) {
    return <Skeleton count={1} height={200} enableAnimation={true} />;
  }

  if (!paymentData) {
    return <Skeleton count={1} height={200} enableAnimation={true} />;
  }

  return (
    <React.Fragment>
      <figure className='border shadow-lg'>
        <div className='px-5 py-4 space-y-1 border-b'>
          <h1 className='text-base font-medium'>Payment Details</h1>
          <p className='text-xs text-gray-500'>Please check the payment details</p>
        </div>
        <div className='p-5 space-y-3'>

            { paymentData.status === "SUCCESS" && 
            <div className='space-y-1'>
              <h1 className='text-green-600 font-semibold text-2xl'>Payment Successfully</h1>
              <p className='text-gray-500 text-sm'>Your payment is successfully completed</p>
            </div> }

            { paymentData.status === "FAILED" && 
            <div className='space-y-1'>
              <h1 className='text-red-600 font-semibold text-2xl'>Payment Failed</h1>
              <p className='text-gray-500 text-sm'>Your payment is not completed</p>
            </div> }

            <div>
              <p className='mb-3'>Transaction Details</p>
              <table className='text-sm text-gray-500'>
                <tr>
                  <td className='pb-1 pr-3'>Gateway</td>
                  <td className='pb-1 pr-3 capitalize'>{paymentData.gateway.toLowerCase()}</td>
                </tr>
                <tr>
                  <td className='pb-1 pr-3'>Status</td>
                  <td className='pb-1 pr-3 capitalize'>{paymentData.status.toLowerCase()}</td>
                </tr>
                <tr>
                  <td className='pb-1 pr-3'>Amount</td>
                  <td className='pb-1 pr-3 capitalize'>{process.env.REACT_APP_CURRENCY_SYMBOL + paymentData.amount.toFixed(2)}</td>
                </tr>
                <tr>
                  <td className='pb-1 pr-3'>Transaction ID</td>
                  <td className='pb-1 pr-3 capitalize'>{paymentData.transaction_id}</td>
                </tr>
              </table>
            </div>

            <div className='py-4'>
              <Link to={`/dashboard/booking/${paymentData.booking.uuid}`} className='btn-primary-sm'>View Booking Details</Link>
            </div>
         
        </div>
      </figure>
    </React.Fragment>
  )
}

export default PaymentSuccess;