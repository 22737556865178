import React from 'react';
import PageHeader from '../../components/Common/PageHeader/PageHeader';


const FaqCard = ({ question, answer }) => {
  return (
    <React.Fragment>
      <figure>
        <details className='p-5 bg-gray-100 border-l-8 border-l-ascent'>
          <summary className='font-semibold cursor-pointer select-none text-lg'>{question}</summary>
          <div className='pt-3 transition duration-300 ease-in-out'>
            <p className='text-sm font-normal leading-relaxed text-gray-600'>{answer}</p>
          </div>
        </details>
      </figure>
    </React.Fragment>
  )
}

const FrequentlyAskedQuestion = () => {

    const faqs = [
        {
          question: "How do I Register as Service Providers, Contractors & Engineers / Architects on Vendor Panel?",
          answer: 'Go to the "Join As Service Provider" Page which is placed at the top of the website, Click there & Sign Up Yourself for Register with authentic Identity documents.'
        },
        {
          question: "What Should I Do If I Forget my Password?",
          answer: 'Go to the " Join As Service Provider" Page just Click on Login Now then you see at bottom of that page "Forgot your Password" Shows there then Click on "Rest Password".'
        },
        {
          question: "Can I Change my Account Information?",
          answer: "Yes, you may change or edit your account information anytime. But this activity arte subject to admin approval."
        },
        {
          question: "How Do I Submit a Bid through the Vendor Panel?",
          answer: 'First you enroll yourself on "Join As Service Provider" then Login your page as Vendor panel after that you enter into Vendor Panel Dashboard then Go To Service Quotes then see the status if shows there "Bid Pending" then you may go to Action Column then "View Quote" & You BID there easily.'
        },
        {
          question: "Can I Track the Progress of my Project on the Vendor Panel?",
          answer: "Yes, On Vendor Panel you May Track the Progress of your work & Projects which is going on."
        },
        {
          question: "How Is My Data Secured on Vendor Panel?",
          answer: "It's Fully Secured because our website fully protect by high Security Systems."
        }
      ]

  return (
    <React.Fragment>
      <PageHeader title={"Frequently Asked Questions"} description={"Look at frequently asked auestions"} crums={[ {
        label: "Frequently Asked Questions",
        path: "/frequently-asked-questions"
      }]} />
      <section>
        <div className='container py-10 space-y-10'>

          <div className='w-full'>
            <div className='space-y-4'>

              {faqs.map((faq, index) => <FaqCard key={index} question={faq.question} answer={faq.answer} />)}

            </div>
          </div>


        </div>
      </section>
    </React.Fragment>
  )
}

export default FrequentlyAskedQuestion;