import React from 'react'
import { FaArrowRightLong } from "react-icons/fa6";
import { FaRegCheckCircle } from "react-icons/fa";

const HowItWorks = () => {

  const StepCard = ({ data }) => {
    const { count, title, summary } = data;
    return (
      <React.Fragment>
        <figure className='w-full bg-white hover:shadow-xl transition duration-300 ease-in-out hover:ease-in-out'>
          <div className='p-10 pb-8 space-y-5'>
            <div className='h-16 w-16 bg-ascent flex items-center justify-center font-medium text-2xl'>
              {count}.
            </div>
            <div className='space-y-3'>
              <h1 className='text-xl font-semibold'>{title}</h1>
              <p className='text-sm text-gray-500 leading-relaxed'>{summary}</p>
            </div>
            <div>
              {
                count != 5 ? <FaArrowRightLong size={40} /> :  <FaRegCheckCircle size={40} />
              }
            </div>
          </div>
        </figure>
      </React.Fragment>
    )
  }

  const howItWorks = [
    {
      count: 1,
      title: "Concept",
      summary: "We listen to your concepts thoroughly and start planning."
    },
    {
      count: 2,
      title: "Design",
      summary: "Our team passionately works to deliver you custom designs."
    },
    {
      count: 3,
      title: "Approval",
      summary: "After designing we seek approval to start developing it."
    },
    {
      count: 4,
      title: "Build",
      summary: "We build your designs with every detailed outlined perfectly."
    },
    {
      count: 5,
      title: "Deliver",
      summary: "We promise on-time delivery with standard quality."
    }
  ]

  // const howItWorks = [
  //   {
  //     count: 1,
  //     title: "Phase Plan",
  //     summary: "This step connects the design process and its miles tones with construction."
  //   },
  //   {
  //     count: 2,
  //     title: "Design Pull Plan",
  //     summary: "We work with the design team to establish a detailed plan for reaching our goals."
  //   },
  //   {
  //     count: 3,
  //     title: "Coordinated Layout",
  //     summary: "This process creates a single point of truth: drawings all project."
  //   },
  //   {
  //     count: 4,
  //     title: "Quality Control",
  //     summary: "Having geometry worked out in the Layout step, prior to construction."
  //   },
  // ]

  return (
    <section className='bg-complement'>
      <div className='container lg:py-20 pt-0 sm:py-16 lg:space-y-20 sm:space-y-10'>

        <div className='flex items-center justify-center text-center flex-col space-y-4'>
          <img src="/assets/Home/portfolio/portfolio-icon.png" alt="portfolio-icon" className='h-[50px] w-auto pb-2' />
          <p className='font-bold lg:text-base sm:text-sm'>PLAN & CONTROL</p>
          <h1 className='font-bold lg:text-4xl sm:text-3xl leading-relaxed'>How it Works</h1>
        </div>

        <div>
          <div className='lg:w-full lg:h-[400px] sm:w-full sm:h-auto flex items-center justify-center overflow-clip'>
            <img src="/assets/Home/how-it-works/img-1.jpg" alt="how-it-works" className='w-full h-auto' />
          </div>
          <div className='grid 2xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-2 sm:grid-cols-1 lg:divide-x sm:divide-x-0 sm:divide-y lg:divide-y-0'>
            {howItWorks.map((data, index) => <StepCard key={index} data={data} />)}
          </div>
        </div>

        

      </div>
    </section>
  )
}

export default HowItWorks