import React from 'react'
import { Link } from 'react-router-dom'

const DetailsDropdown = ({ title, description, btnText }) => {
  return (
    <React.Fragment>
      <figure>
        <details className='p-4 bg-gray-100 border-l-8 border-l-ascent select-none'>
          <summary className='font-medium cursor-pointer select-none text-base'>{title}</summary>
          <div className='pt-3 transition duration-300 ease-in-out'>
            <p className='text-sm font-normal leading-relaxed text-gray-600'>{description}</p>
            <div className='mt-4'>
              <Link to={'/make-quote'} className='btn-primary-sm'><span>{btnText}</span></Link>
            </div>
          </div>
        </details>
      </figure>
    </React.Fragment>
  )
}

const About = () => {

  const details = [
    {
      title: "STEP 1. GET MAP AUTHENTICATION CONSULTATION",
      description: "Consider Local zoning laws and regulations to ensure the proposed house map complies with legal requirements.",
      btnText: 'CONSULT NOW'
    },
    {
      title: "STEP 2. CONSULT ARCHITECT",
      description: "Balance aesthetics with Functionality, incorporating design elements that align with the client’s vision and architectural style. ",
      btnText: 'CONSULT NOW'
    }
    ,{
      title: "STEP 3. CONSULT CIVIL ENGINEER",
      description: "Collaborate with our engineers to ensure the house map aligns with safety standards and can support the intended construction. You may also request for SITE VISIT for your site supervision.",
      btnText: 'CONSULT NOW'
    }
    ,{
      title: "STEP 4. GET TECHNICAL & PRODUCT CONSULTATION",
      description: "Evaluate the technical, financial, and operational feasibility of the proposed product, considering resource availability and constraints.",
      btnText: 'CONSULT NOW'
    },
    ,{
      title: "STEP 5. FIND EXPERIENCED BONAFIDE & RELIABLE CONTRACTORS OR OTHER PROFESSIONALS",
      description: "Compare and choose the most efficient and reliable contractors or professionals for construction & whole building related services for your home.",
      btnText: 'SERVICE REQUEST'
    },
  ]

  return (
    <section>
      <div className='container lg:py-20 sm:py-16'>
        <div className='grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-16 items-center'>

          <figure>
            <img src="/assets/Home/about/about-img.png?v=2" alt="about-img" className='lg:h-[600px] md:h-[400px] sm:h-[300px] w-auto mx-auto' />
          </figure>

          <figure className='space-y-7'>
            <p className='font-bold'>WELCOME TO HOMEGURUS</p>
            <h2 className='font-bold text-5xl'>Planning to Build A Home?</h2>
            <p className='text-gray-500 leading-loose'>Get Full Consultancy with Experienced Industry Leaders as Architect / Civil Engineer before start your work. After planning you get experienced & Bonafide contractors or professionals for your dream home through our platform.</p>
            
            <div className='space-y-2.5'>
              {details.map((detail, index) => <DetailsDropdown key={index} title={detail.title} description={detail.description} btnText={detail.btnText} />)}
            </div>
            {/* <p className='text-gray-500 leading-loose'>Experience a hassle-free journey with "Build With Us." Our dedicated team ensures seamless construction, tailored to your lifestyle. From inception to completion, we prioritize your vision, employing skilled craftsmanship and transparent communication to create your dream home efficiently and beautifully</p> */}
            {/* <div>
              <img src="/assets/Home/about/signature.png" alt="signature" className='pb-5 h-[70px]' />
              <p className='leading-loose font-semibold text-sm'>Walimes Jonnie <br />Director of Constro Company</p>
            </div> */}
          </figure>

        </div>
      </div>
    </section>
  )
}

export default About