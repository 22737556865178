import React from 'react'
import { FaWhatsapp } from "react-icons/fa";

const FloatingWhatsapp = () => {
  return (
    <div className='fixed z-50 lg:bottom-10 lg:right-10 sm:bottom-8 sm:right-8'>
        <a href='https://wa.me/919031029800' className='h-16 w-16 bg-ascent flex items-center justify-center rounded-full shadow-[0px_0px_25px_rgba(0,0,0,0.4)]'>
            <FaWhatsapp size={30} />
        </a>
    </div>
  )
}

export default FloatingWhatsapp