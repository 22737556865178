import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-creative';
import Slider from './Slider';
import { EffectCreative, Autoplay, Navigation } from 'swiper/modules';

const slides = [
  {
    title: 'Civil Consultant & Construction Services',
    description: 'One Stop Solution for every construction & renovation needs in India.',
    imageUrl: '/assets/Home/intro-slider/5.jpg',
    buttonText: 'Consult Now',
    buttonLink: '/contact'
  },
  {
    title: 'Get More leads for your Business, Enroll Now to Multiply your Earning. ',
    description: "We connect Directly to Clients on the basis of their PIN Code for Architect / Civil Engineers & Contractors of all categories. Opportunity for civil Contractors Become our service provider for Your new contracts",
    imageUrl: '/assets/Home/intro-slider/6.jpg',
    buttonText: 'Register Now',
    buttonLink: 'https://app.homegurus.in/vendor/register'
  },

  {
    title: 'Avail Our Services to Build your Building with Complete Construction Awareness.',
    description: 'Connect to multiple contractors of all categories for your new home at a time with quality and budget comparison our dedicated team helps you in the construction of your residential or commercial projects with complete construction awareness.',
    imageUrl: '/assets/Home/intro-slider/7.jpg',
    buttonText: 'Service Request',
    buttonLink: '/make-quote'
  },
  {
    title: 'Learn Skill And Earn More Money With Best Service',
    description: 'We provide Skill learning Programs on Civil and technical Works to enhance the skill and provide Opportunity to Connect with Customers with more confidence and get more leads.',
    imageUrl: '/assets/Home/intro-slider/8.jpg',
    buttonText: 'Register Now',
    buttonLink: 'https://app.homegurus.in/vendor/register'
  }
  // {
  //   title: 'We Design Your Workspace With Elegance',
  //   description: 'Workspace defines your brand identity. Let our designing team turn your workspace into a productive culture.',
  //   imageUrl: '/assets/Home/intro-slider/4.jpg'
  // },
];

const IntroSlider = () => {
  return (
    <React.Fragment>
      <section>
        <Swiper
        style={{
          "--swiper-pagination-color": "#fff",
          "--swiper-navigation-color": "#fff",
          "--swiper-navigation-sides-offset": "30px"
        }}      
          loop={true}
          speed={1000}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
         }}    
          grabCursor={true}
          effect={'creative'}
          navigation={true}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: ["-20%", 0, -1],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          modules={[EffectCreative, Autoplay, Navigation]}>
          {slides.map((item, index) => (
            <SwiperSlide key={index}>
              <Slider data={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </React.Fragment>
  )
}

export default IntroSlider