import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import TestimonialCard from './TestimonialCard';
import axios from 'axios';

// const testimonials = [
//   {
//     name: 'Sham Sharma',
//     summary: "Seamless Experience, Stunning Result",
//     description: "Building our home with HomeGurus was an absolute delight! Their commitment to a hassle-free process truly stood out. From conceptualization to the final build, they listened attentively to our preferences, ensuring our lifestyle needs were met. The result? A stunning home that perfectly reflects our vision.",
//   },
//   {
//     name: 'Shubham Kumar',
//     summary: "Expertise with a Personal Touch",
//     description: "HomeGurus' expertise combined with their personal approach made our construction journey exceptional. They meticulously guided us through each phase, providing transparent communication and detailed updates. The craftsmanship in our home speaks volumes about their dedication to quality."
//   },
//   {
//     name: 'Manoj Yadav',
//     summary: "Efficient, Beautiful, and Tailored",
//     description: "Choosing HomeGurus was the best decision we made for our dream home. Their seamless process ensured an efficient build without compromising on aesthetics. They flawlessly incorporated our ideas into a beautifully crafted space that suits our lifestyle perfectly.",
//   },
//   {
//     name: 'Kumar Saurabh',
//     summary: "Beyond Expectations, Beyond Satisfied",
//     description: "HomeGurus exceeded our expectations in every way. Their dedication to our vision and transparent communication made the construction process smooth and stress-free. The result is a home that not only meets but surpasses our desires. We're genuinely thrilled with the outcome!"
//   }
// ]

const Testimonial = () => {

  const [testimonials, setTestimonials] = useState([]);

  useState(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/testimonial`);
      setTestimonials(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <React.Fragment>
      <section>
        <div className='container lg:py-20 sm:py-16'>
          <div className='grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 items-center gap-10'>

            <div className='space-y-10 lg:pb-0 md:pb-0 sm:pb-10 lg:order-1 md:order-1 sm:order-2'>
              <div className='text-left space-y-3'>
                <p className='font-bold lg:text-base sm:text-sm'>TESTIMONIALS</p>
                <h1 className='font-bold lg:text-5xl sm:text-3xl leading-relaxed'>Client’s Reviews</h1>
              </div>
              <Swiper
              className='testimonial-slider !overflow-y-visible'
                style={{
                  "--swiper-pagination-color": "#fff",
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#FFEE02",
                  "--swiper-navigation-sides-offset": "30px",
                  "--swiper-pagination-bottom": "-60px",
                  "--swiper-pagination-bullet-width": "40px",
                  "--swiper-pagination-bullet-height": "12px",
                  "--swiper-pagination-bullet-border-radius": "0px",
                  "--swiper-pagination-bullet-horizontal-gap": "2px",
                  "--swiper-pagination-bullet-inactive-opacity": "0.1"
                }}
                loop={true}
                speed={1000}
                autoplay={true}
                grabCursor={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination, Autoplay]}>
                {testimonials.map((item, index) => (
                  <SwiperSlide key={index}>
                    <TestimonialCard data={item} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className='lg:order-2 md:order-2 sm:order-1'>
              <img src="/assets/Home/testimonial/testimonial.png" alt="img-1" className='w-full h-auto lg:p-10' />
            </div>

          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Testimonial