import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from '../Common/Footer/Footer';
import DesktopHeader from '../Common/Header/DesktopHeader';
import MobileHeader from '../Common/Header/MobileHeader';
import FloatingWhatsapp from '../Common/FloatingWhatsapp/FloatingWhatsapp';

const Template = () => {

  const { pathname } = useLocation();

  const [topPadding, setTopPadding] = useState(0);

  useEffect(() => {
    let height = document.getElementById('desktop-header').clientHeight;
    if (height == 0) {
      height = document.getElementById('mobile-header').clientHeight;
    }
    setTopPadding(height);
    window.scroll(0, 0);
  }, [pathname]);

  return (
    <React.Fragment>
      <DesktopHeader/>
      <MobileHeader/>
      <FloatingWhatsapp />
      <main style={{ paddingTop: `${ (pathname == "/") ? 0 : topPadding}px` }}>
        <Outlet />
      </main>
      <Footer/>
    </React.Fragment>
  )
}

export default Template;