import { USER_LOGGED_IN, USER_LOGGED_OUT } from "../constants/AuthConstant";

const AuthReducer = (state = null, action) => {
    switch (action.type) {
        case USER_LOGGED_IN: {
            state = action.payload;
            return state;
        }
        case USER_LOGGED_OUT: {
            state = null;
            return state;
        }
        default:
            return state;
    }
}

export default AuthReducer;