import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  BiMap,
  BiMailSend,
  BiPhoneCall,
  BiChevronsRight,
  BiMessageSquareDetail,
  BiHeadphone,
} from "react-icons/bi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FaFacebookSquare,
  FaTwitter,
  FaLinkedin,
  FaInstagram,
} from "react-icons/fa";
import { RiWhatsappLine } from "react-icons/ri";
import { MdOutlineSupportAgent } from "react-icons/md";

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleNewsletterEmailForm = async (event) => {
    event.preventDefault();
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/newsletter-email`,
      {
        email: email,
      }
    );
    if (response.data.status) {
      setEmail("");
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
  };

  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       autoDisplay: false
  //     },
  //     "google_translate_element"
  //   );
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     var addScript = document.createElement("script");
  //     addScript.setAttribute(
  //       "src",
  //       "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //     );
  //     document.body.appendChild(addScript);
  //     window.googleTranslateElementInit = googleTranslateElementInit;
  //   }, 500);
  // }, []);

  return (
    <React.Fragment>
      <footer>
        <nav className="bg-[#232222] py-6">
          <div className="container">
            <div className="flex lg:flex-row sm:flex-col items-center justify-between gap-7">
              <div>
                <img
                  src="/assets/Logo/logo.png"
                  alt="logo"
                  className="h-[50px] w-auto"
                />
              </div>
              <div className="text-center">
                <p className="text-white leading-loose">
                  Take a free call to our support team{" "}
                  <a href="tel:+91-9031029800" className="text-ascent">
                    +91-9835431226
                  </a>
                </p>
              </div>
              <div>
                <Link
                  to={"/contact"}
                  className="btn-primary-md flex space-x-2 items-center"
                >
                  <span>Get Consultation</span>
                  <BiMessageSquareDetail size={20} />
                </Link>
              </div>
            </div>
          </div>
        </nav>
        <nav className="bg-black py-20 border-b-[1px] border-b-gray-700">
          <div className="container">
            <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-10">
              <div className="space-y-4">
                <h6 className="font-semibold text-xl text-white">
                  About HomeGurus
                </h6>
                <p className="text-sm text-gray-300 leading-loose">
                  We merge functionality with aesthetics, tailoring efficient
                  home construction solutions for homeowners. Our specialized
                  team excels in crafting personalized houses, dedicated to
                  creating a home that perfectly complements your unique
                  lifestyle.
                </p>
                <div>
                  <Link
                    to={"/about"}
                    className="text-sm text-ascent hover:text-white"
                  >
                    Learn more
                  </Link>
                </div>
                <div>
                  <p className="text-sm text-gray-300 leading-loose">
                    CIN No. U4321BR2023PTC065708
                  </p>
                  <p className="text-sm text-gray-300 leading-loose">
                    GSTIN: 10AAHCH0269C1Z8
                  </p>
                </div>
              </div>

              <div className="space-y-4 lg:pl-10">
                <h6 className="font-semibold text-xl text-white">
                  Information
                </h6>
                <ul className="space-y-2">
                  <li className="flex gap-1 items-center">
                    <BiChevronsRight size={25} className="fill-ascent" />
                    <Link
                      to={"/careers"}
                      className="text-white text-sm leading-loose hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out"
                    >
                      Careers
                    </Link>
                  </li>
                  <li className="flex gap-1 items-center">
                    <BiChevronsRight size={25} className="fill-ascent" />
                    <Link
                      to={"/privacy-policy"}
                      className="text-white text-sm leading-loose hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="flex gap-1 items-center">
                    <BiChevronsRight size={25} className="fill-ascent" />
                    <Link
                      to={"/terms-conditions"}
                      className="text-white text-sm leading-loose hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out"
                    >
                      Terms & Conditions
                    </Link>
                  </li>
                  <li className="flex gap-1 items-center">
                    <BiChevronsRight size={25} className="fill-ascent" />
                    <Link
                      to={"/refund-policy"}
                      className="text-white text-sm leading-loose hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out"
                    >
                      Refund Policy
                    </Link>
                  </li>
                  <li className="flex gap-1 items-center">
                    <BiChevronsRight size={25} className="fill-ascent" />
                    <Link
                      to={"/shipping-policy"}
                      className="text-white text-sm leading-loose hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out"
                    >
                      Shipping Policy
                    </Link>
                  </li>
                  <li className="flex gap-1 items-center">
                    <BiChevronsRight size={25} className="fill-ascent" />
                    <Link
                      to={"/frequently-asked-questions"}
                      className="text-white text-sm leading-loose hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out"
                    >
                      Frequently Asked Questions
                    </Link>
                  </li>
                  <li className="flex gap-1 items-center">
                    <BiChevronsRight size={25} className="fill-ascent" />
                    <a
                      target="_blank"
                      href={`${process.env.REACT_APP_BACKEND_BASE_URL}/vendor/login`}
                      className="text-white text-sm leading-loose hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out"
                    >
                      Vendor Login
                    </a>
                  </li>
                </ul>
              </div>

              <div className="space-y-4">
                <h6 className="font-semibold text-xl text-white">Contact</h6>
                <ul className="space-y-3">
                  <li className="flex gap-3">
                    <BiMap size={40} className="fill-ascent" />
                    <p className="text-white text-sm leading-loose">
                      C/O Padam Pratap NR Singh,Chanakya Colony, Dighi Kala,
                      Hajipur, Hajipur, Bihar 844102
                    </p>
                  </li>
                  <li className="flex gap-3">
                    <BiMailSend size={25} className="fill-ascent" />
                    <a
                      href="mailto:info@homegurus.in"
                      className="text-white text-sm leading-loose"
                    >
                      info@homegurus.in
                    </a>
                  </li>
                  <li className="flex gap-3">
                    <BiHeadphone size={25} className="fill-ascent" />
                    <a
                      href="mailto:customercare@homegurus.in"
                      className="text-white text-sm leading-loose"
                    >
                      customercare@homegurus.in
                    </a>
                  </li>
                  <li className="flex gap-3">
                    <MdOutlineSupportAgent size={25} className="fill-ascent" />
                    <a
                      href="tel:919031029801"
                      className="text-white text-sm leading-loose"
                    >
                      +91-9031029801
                    </a>
                  </li>
                  <li className="flex gap-3">
                    <BiPhoneCall size={25} className="fill-ascent" />
                    <a
                      href="tel:919031029800"
                      className="text-white text-sm leading-loose"
                    >
                      +91-9031029800
                    </a>
                  </li>
                  <li className="flex gap-3">
                    <RiWhatsappLine size={25} className="fill-ascent" />
                    <a
                      href="https://wa.me/919031029800"
                      className="text-white text-sm leading-loose"
                    >
                      Chat on whatsapp
                    </a>
                  </li>
                </ul>
              </div>

              <div className="space-y-5">
                <div className="space-y-4">
                  <h6 className="font-semibold text-xl text-white">
                    Newsletter
                  </h6>
                  <p className="text-sm text-gray-300 leading-loose">
                    Signup for our weekly newsletter to get the latest news.
                  </p>
                  <form
                    className="flex"
                    onSubmit={(event) => handleNewsletterEmailForm(event)}
                  >
                    <input
                      type="email"
                      value={email}
                      className="py-3.5 border border-gray-900 px-4 bg-gray-900 text-sm w-full text-white outline-none"
                      placeholder="Email Address"
                      required
                      minLength={10}
                      maxLength={100}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    <button type="submit" className="btn-primary-sm">
                      Submit
                    </button>
                  </form>
                </div>
                <div className="space-y-4">
                  <h6 className="font-semibold text-xl text-white">
                    Social Media
                  </h6>
                  <p className="text-sm text-gray-300 leading-loose">
                    Follow our social media accounts
                  </p>
                  <ul className="flex items-center justify-start space-x-5">
                    <li>
                      <a href="#">
                        <FaFacebookSquare className="fill-ascent" size={20} />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaTwitter className="fill-ascent" size={20} />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaLinkedin className="fill-ascent" size={20} />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaInstagram className="fill-ascent" size={20} />
                      </a>
                    </li>
                  </ul>
                </div>
                <div id="google_translate_element"></div>
              </div>
            </div>
          </div>
        </nav>
        <nav className="bg-black py-6">
          <div className="container">
            <div className="flex lg:flex-row sm:flex-col items-center justify-between gap-5">
              <div className="text-center">
                <p className="text-gray-300 text-sm leading-loose">
                  Copyrights © 2023 Home Gurus All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </nav>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
