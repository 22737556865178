import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import PageHeader from "../../components/Common/PageHeader/PageHeader";
import { ToastContainer, toast } from "react-toastify";
import { FaLocationDot } from "react-icons/fa6";
import { MdPeople } from "react-icons/md";
import { RiFileList2Line } from "react-icons/ri";
import { MdOutlineDescription } from "react-icons/md";


const Careers = () => {
  const applicationFormRef = useRef(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [resume, setResume] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [experience, setExperience] = useState("");
  const [jobCards, setJobCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [error, setError] = useState(null);

  // Fetch jobs from API
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/active/jobs`)
      .then((response) => {
        setJobCards(response.data.data); // Store the job data from API
        setLoading(false);
      })
      .catch((err) => {
        setError("Failed to load jobs"); // Handle error
        setLoading(false);
      });
  }, []);

  const scrollToSection = () => {
    const section = document.getElementById("current-openings");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Handle resume file change
  const handleResumeChange = (e) => {
    setResume(e.target.files[0]);
  };

  const handleJobClick = (job) => {
    setSelectedJob(job);
    if (applicationFormRef.current) {
      applicationFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoader(true);

    if (!selectedJob) {
      alert("Please select a job");
      return;
    }

    const formData = new FormData();
    formData.append("job_id", selectedJob.id);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("experience", experience);
    if (resume) {
      formData.append("resume_path", resume);
    }

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/job/response`, formData)
      .then((response) => {
        toast.success("Application submitted successfully!");
        setSubmitLoader(false);
        console.log(response);
        // Reset form fields
        setName("");
        setEmail("");
        setPhone("");
        setExperience("");
        setResume(null);
        setSelectedJob(null);
      })
      .catch((err) => {
        toast.error("Failed to submit application");
        setSubmitLoader(false);
        console.error(err);
      });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <PageHeader
        title={"Careers"}
        description={"Look at opportunities in our company"}
        crums={[
          {
            label: "Careers",
            path: "/careers",
          },
        ]}
      />

      <section>
        <div className="container py-20 space-y-16">
          <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-10 items-center">
            <figure>
              <img
                src="/assets/Careers/img-1.jpg"
                alt="img-1"
                className="w-full h-auto shadow-lg p-5"
              />
            </figure>

            <figure className="space-y-5">
              <h1 className="text-4xl font-bold">
                Join Our Journey of Innovation
              </h1>

              <div className="space-y-4">
                <h2 className="text-2xl font-semibold">
                  Company Culture & Environment:
                </h2>

                <p className="text-xl font-semibold">Welcome to HomeGurus</p>

                <p>
                  At HomeGurus, we believe that our most valuable asset is our
                  people. Our culture is built on a foundation of collaboration,
                  innovation, and mutual respect, ensuring that every team
                  member feels valued and empowered to reach their full
                  potential.
                </p>

                <p>
                  A Dynamic and Supportive Environment at our Workplace where
                  you will find Growth Opportunities, Inclusive Work Culture,
                  Learning and Development, Work-Life Balance, and a strong
                  focus on Employee Well-being.
                </p>

                <p>
                  When you join HomeGurus, you become part of a vibrant
                  community that values your contributions and supports your
                  professional journey. Our commitment to fostering a positive
                  and engaging workplace makes HomeGurus not just a place to
                  work, but a place to grow and thrive. If you are passionate,
                  driven, and ready to make a difference, we invite you to
                  explore career opportunities with us. Together, we can achieve
                  great things and shape the future of our industry.
                </p>

                <h2 className="text-xl font-medium">
                  Are You Interested in joining our team?
                </h2>
                <p>
                  <a
                    className="text-yellow-500 font-semibold"
                    onClick={scrollToSection}
                  >
                    CLICK HERE
                  </a>{" "}
                  For Current Openings and find the right opportunity for you at
                  HomeGurus.
                </p>
              </div>
            </figure>
          </div>

          <section id="current-openings" className="space-y-10">
            <h1 className="text-4xl font-bold text-center">Current Openings</h1>

            {/* Loading and Error Handling */}
            {loading && (
              <div className="text-center">
                <div className="spinner"></div>
              </div>
            )}
            {error && <p className="text-center text-red-500">{error}</p>}

            {/* Job Cards */}
            <div className="grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-10">
  {!loading &&
    !error &&
    jobCards.length > 0 &&
    jobCards.map((job) => (
      <div
        key={job.id}
        className="cursor-pointer p-6 bg-white shadow-lg rounded-lg hover:bg-gray-100 transition relative"
      >
        <h2 className="text-2xl font-semibold">{job.title}</h2>

        {/* Location and Positions */}
        <div className="flex gap-4 mt-2 md:flex-row md:gap-4">
          <p className="text-lg text-gray-700 break-words flex items-center md:text-md">
            <FaLocationDot className="mr-1" />
            Location: {job.location}
          </p>
          <p className="text-lg text-gray-700 break-words flex items-center md:text-md">
            <MdPeople size={22} className="mr-1" />
            Positions: {job.number_of_positions}
          </p>
        </div>

        {/* Dates and Status */}
        <div className="flex flex-col gap-4 mt-3 md:flex-row md:gap-4">
          <p className="text-sm text-gray-500 md:text-sm md:text-gray-700">
            <strong>Opening Date:</strong>{" "}
            {new Date(job.opening_date).toLocaleDateString()}
          </p>
          <p className="text-sm text-gray-500 md:text-sm md:text-gray-700">
            <strong>Closing Date:</strong>{" "}
            {new Date(job.closing_date).toLocaleDateString()}
          </p>
          <p className="text-sm text-gray-500 md:text-sm md:text-gray-700">
            <strong>Status:</strong>{" "}
            {job.status === 1 ? "Active" : "Inactive"}
          </p>
          <p className="text-sm text-gray-500 md:text-sm md:text-gray-700">
            <strong>Created At:</strong>{" "}
            {new Date(job.created_at).toLocaleDateString()}
          </p>
        </div>

        {/* Description */}
        <p className="mt-2 text-gray-600 break-words flex items-start">
          <MdOutlineDescription size={22} className="mr-1" />
          <strong>Description: </strong>
          {job.description}
        </p>

        {/* Requirements */}
        <p className="mt-2 text-gray-600 break-words flex items-start">
          <RiFileList2Line size={22} className="mr-1" />
          <strong>Requirements: </strong>
          {job.requirements}
        </p>

        {/* Apply Now Button on small screens */}
        <div className="md:mt-8 mt-4">
          <button
            onClick={() => handleJobClick(job)}
            className="btn-primary-md w-full md:absolute md:bottom-6 md:right-6 md:w-auto"
          >
            Apply Now
          </button>
        </div>
      </div>
    ))}
</div>
            {/* Job Application Form */}
            {selectedJob && (
              <div ref={applicationFormRef} className="space-y-5">
                <div className="text-center">
                  <h1 className="font-semibold text-xl">
                    Apply for {selectedJob.title}
                  </h1>
                </div>
                <div className="lg:w-8/12 md:w-full sm:w-full mx-auto space-y-10">
                  <form className="space-y-6" onSubmit={handleSubmit}>
                    <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-6">
                      <div className="input-group">
                        <label htmlFor="name" className="input-label">
                          Your Name <em>*</em>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Your Name"
                          className="input-box-md"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>

                      <div className="input-group">
                        <label htmlFor="email" className="input-label">
                          Email <em>*</em>
                        </label>
                        <input
                          type="email"
                          placeholder="Enter Email Address"
                          name="email"
                          className="input-box-md"
                          value={email}
                          minLength={1}
                          maxLength={100}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-6">
                      <div className="input-group">
                        <label htmlFor="phone" className="input-label">
                          Phone Number <em>*</em>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Phone Number"
                          name="phone"
                          className="input-box-md"
                          value={phone}
                          onChange={(e) => {
                            // Enforce a maximum of 10 characters, allowing backspace (keyCode 8)
                            const newValue = e.target.value.slice(0, 10);
                            setPhone(newValue);
                          }}
                          maxLength="10" // Add maxLength attribute for browser validation (optional)
                          required
                        />
                      </div>

                      <div className="input-group">
                        <label htmlFor="experience" className="input-label">
                          Experience <em>*</em>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Experience"
                          name="experience"
                          className="input-box-md"
                          value={experience}
                          onChange={(e) => setExperience(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <label htmlFor="resume" className="input-label">
                        Resume/CV <em>*</em>
                      </label>
                      <input
                        type="file"
                        id="resume"
                        accept=".pdf,.doc,.docx"
                        onChange={handleResumeChange}
                        required
                      />
                    </div>

                    <div className="lg:col-span-3 md:col-span-3 sm:col-span-1">
                      <button
                        type="submit"
                        className="btn-primary-md w-full"
                        disabled={submitLoader}
                      >
                        {submitLoader
                          ? "Submitting Application..."
                          : "Submit Application"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </section>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Careers;
