import React from "react";
import { Link } from "react-router-dom";
import { TbChevronRight } from "react-icons/tb";

const Breadcrumb = ({ crums }) => {
    return (
    <React.Fragment>
        <header className="py-5 bg-white border-b w-full">
            <div className="container">
                <ul className="flex items-center space-x-3 text-gray-600 text-[0.8rem]">
                    <li>
                        <Link to={"/"} className="flex items-center font-medium">Home</Link>
                    </li>
                    {crums.map(({ label, path }) => (
                        <li key={label}>
                            <Link to={path} className="flex items-center font-medium">
                            <span className="mr-3 text-gray-500"><TbChevronRight size={15} /></span> {label}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </header>
    </React.Fragment>);
}

export default Breadcrumb;