import React, { useState } from "react";
import PageHeader from "../../components/Common/PageHeader/PageHeader"
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiPhoneCall } from "react-icons/bi";
import { toast } from 'react-toastify';
import axios from 'axios';
import { HiOutlineLocationMarker } from "react-icons/hi";

const Contact = () => {

  const fields = {
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  };

  const [inputFields, setInputFields] = useState(fields);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputFields({ ...inputFields, [name]: value });
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/contact-enquiry`, inputFields);
      if (response.data.status) {
        setInputFields(fields);
        event.target.reset();
        toast.success(response.data.message);
      }
      else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('An error occured');
    }
  }

  return (
    <React.Fragment>
      <PageHeader
        title={"Contact us"}
        description={"Get in touch with us"}
        crums={[
          {
            label: "Contact us",
            path: "/contact",
          },
        ]}
      />
      <section>
        <div className="container py-20 space-y-16">

          <div className="lg:w-4/12 md:w-8/12 sm:w-full space-y-3 text-center mx-auto">
            <h1 className="lg:text-4xl sm:text-3xl font-bold">Keep in touch with us</h1>
            <p className="text-gray-500 text-sm">
              Where we build dreams into reality. If you're looking for a
              reliable partner for your construction projects
            </p>
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-10">
            <div className="flex items-center py-6 px-9 bg-ascent bg-opacity-10 rounded-sm">
              <HiOutlineMailOpen size={60} className="text-yellow-600 mr-4" />
              <div>
                <h1 className="text-2xl font-semibold">Send us an email</h1>
                <p className="text-sm font-medium pt-1">You can reach out to us at</p>

                <div className="flex gap-2">
                  <a href="mtilto:nfo@homegurus.in" className="text-sm pt-1 text-yellow-700">info@homegurus.in</a>
                  <a href="mailto:customercare@homegurus.in" className="text-sm pt-1 text-yellow-700">customercare@homegurus.in</a>
                </div>
              </div>
            </div>
            <div className="flex items-center py-6 px-9 bg-ascent bg-opacity-10 rounded-sm">
              <HiOutlineLocationMarker size={53} className="text-yellow-600 mr-4" />
              <div>
                <h1 className="text-2xl font-semibold">Office Location</h1>
                <p className="text-sm font-medium pt-1">Visit our office</p>
                <p className="text-sm pt-1 text-yellow-700">C/O Padam Pratap NR Singh,Chanakya Colony, Dighi Kala, Hajipur, Bihar 844102</p>
              </div>
            </div>
            <div className="flex items-center py-6 px-9 bg-ascent bg-opacity-10 rounded-sm">
              <BiPhoneCall size={53} className="text-yellow-600 mr-4" />
              <div>
                <h1 className="text-2xl font-semibold">Call us on</h1>
                <p className="text-sm font-medium pt-1">You can call us on</p>
                <div className="flex gap-2">
                  <a href="tel:9835431226" className="text-sm pt-1 text-yellow-700">+91-9835431226</a>
                  <a href="tel:9031029800" className="text-sm pt-1 text-yellow-700">+91-9031029800</a>
                </div>
              </div>
            </div>
          </div>

          <div className="lg:w-8/12 md:w-full sm:w-full mx-auto space-y-10">
            <div className="space-y-3 text-center">
              <h1 className="lg:text-4xl sm:text-3xl font-bold"> Send your message to us</h1>
              <p className="text-sm text-gray-500">Please fill the required fields</p>
            </div>

            <form className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-5" onSubmit={(event) => handleFormSubmit(event)}>

              {/* NAme */}
              <div className="input-group">
                <label htmlFor="name" className='input-label'>Your Name <em>*</em></label>
                <input type="text" name='name' className='input-box-md' placeholder='Enter Name' value={inputFields.name} minLength={1} maxLength={100} onChange={(event) => handleInputChange(event)} required/>
              </div>

              {/* Email Address */}
              <div className="input-group">
                <label htmlFor="email" className='input-label'>Email Address <em>*</em></label>
                <input type="email" name='email' className='input-box-md' placeholder='Enter Email Address' value={inputFields.email} minLength={1} maxLength={100} onChange={(event) => handleInputChange(event)} required/>
              </div>

              {/* Phone */}
              <div className="input-group">
                <label htmlFor="phone" className='input-label'>Phone <em>*</em></label>
                <input type="tel" pattern='[0-9]{10}' name='phone' className='input-box-md' placeholder='Enter Phone (10 Digits)' value={inputFields.phone} minLength={10} maxLength={10} onChange={(event) => handleInputChange(event)} required/>
              </div>

              {/* Subject */}
              <div className="input-group lg:col-span-3 md:col-span-3 sm:col-span-1">
                <label htmlFor="subject" className='input-label'>Subject <em>*</em></label>
                <input type="text" name='subject' className='input-box-md' placeholder='Enter Subject' value={inputFields.subject} minLength={1} maxLength={250} onChange={(event) => handleInputChange(event)} required/>
              </div>

              {/* Message */}
              <div className="input-group lg:col-span-3 md:col-span-3 sm:col-span-1">
                <label htmlFor="message" className='input-label'>Message <em>*</em></label>
                <textarea name="message" id="message" className="input-box-md" rows="5" placeholder="Write Message" value={inputFields.message} minLength={1} maxLength={500} onChange={(event) => handleInputChange(event)} required></textarea>
              </div>

              <div className="lg:col-span-3 md:col-span-3 sm:col-span-1">
                <button type="submit" className="btn-primary-md w-full">Send Message</button>
              </div>

            </form>
            

          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Contact;
