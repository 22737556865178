import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules'
import "swiper/css";
import axios from 'axios';

const BrandCard = ({ logo, name }) => {
  return (
    <React.Fragment>
      <figure className='w-[200px] flex items-center justify-center bg-complement p-5 shadow-lg border'>
        <img src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${logo}`} alt={name} className='h-[60px] w-fit' />
      </figure>
    </React.Fragment>
  )
}

const Brand = () => {

  const [brands, setBrand] = useState([]);

  useState(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/brand`);
      setBrand(response.data.data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <React.Fragment>
      <section>
        <div className='container lg:py-20 pb-0 sm:py-16 lg:space-y-20 sm:space-y-10'>

          <div className='flex items-center justify-center text-center flex-col space-y-4'>
            <img src="/assets/Home/portfolio/portfolio-icon.png" alt="portfolio-icon" className='h-[50px] w-auto pb-2' />
            <p className='font-bold lg:text-base sm:text-sm'>BRANDS</p>
            <h1 className='font-bold lg:text-4xl sm:text-3xl leading-relaxed'>Our Partners & Our Customer’s Choice</h1>
            <p className='text-gray-600 text-base leading-normal'>We work with your chosen brands for your dream home or projects.</p>
          </div>

          <div className='lg:w-10/12 md:w-10/12 sm:w-full mx-auto'>
            <div className='space-y-4'>

              <Swiper 
              loop={true}
              speed={1000}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              grabCursor={true}
              breakpoints={{
                400: {
                  slidesPerView: 1,
                },
                600: {
                  slidesPerView: 2,
                },
                1000: {
                  slidesPerView: 3,
                },
                1100: {
                  slidesPerView: 4,
                },
              }} modules={[ Autoplay ]}>
              {brands.map((brand, index) => (
                <SwiperSlide className='p-5' key={index}>
                  <BrandCard logo={brand.logo} name={brand.name} />
                </SwiperSlide>
              ))}
              </Swiper>



            </div>
          </div>

        </div>
      </section>
    </React.Fragment>
  )
}

export default Brand;