import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa6";

const Slider = ({ data }) => {

  const {
    imageUrl,
    title,
    description,
    buttonText,
    buttonLink
  } = data;

  return (
    <React.Fragment>
      <figure className='h-screen w-full flex items-center justify-center overflow-clip relative'>
        <div className='absolute w-full h-full z-10 bg-black bg-opacity-40' />
        <img src={imageUrl} alt="slider" className='lg:h-auto lg:w-full md:h-auto md:w-full sm:h-full sm:w-auto relative z-0' />
        <div className='absolute w-full h-full top-0 left-0 z-20'>
          <div className='container h-full relative'>
            <div className='h-full lg:w-8/12 relative top-0 z-20 md:w-full sm:w-full flex flex-col lg:items-start md:items-start sm:items-center lg:text-left md:text-left sm:text-center justify-center lg:px-20 space-y-5'>
              <h1 className='text-white font-bold lg:text-6xl md:text-5xl sm:text-4xl mt-20 lg:leading-snug md:leading-snug sm:leading-relaxed'>{title}</h1>
              <p className='text-gray-100 lg:text-xl md:text-lg sm:text-base leading-loose'>{description}</p>
              <div className='pt-0'>
                <Link to={buttonLink} className='btn-primary-md mt-4 flex items-center justify-center space-x-2 w-fit'>
                  <span>{buttonText}</span>
                  <FaArrowRight />
                </Link>
              </div>
            </div>
            <div className='slide-shapes absolute h-full w-full z-10 right-10 top-0 lg:flex md:flex sm:hidden items-center justify-center'>
              <div className='shape-1 absolute right-0'></div>
              <div className='shape-2 absolute right-[100px] bottom-0'></div>
            </div>
          </div>
        </div>
      </figure>
    </React.Fragment>
  )
}

export default Slider